import type { Point } from './point';
import type { MatrixTransform } from './point-transform';
import { transformPoint } from './point-transform';

export type Polygon<TPoint extends Point = Point> = {
  points: TPoint[];
};

/**
 * Transform all points with a matrix transform.
 * This works the same as the `matrix()` function of
 * SVG element's `transform` attribute.
 * {@see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform#matrix}
 */
export function transformPolygon<TPolygon extends Polygon>(
  polygon: TPolygon,
  // `e` is not an abbreviation here
  // eslint-disable-next-line unicorn/prevent-abbreviations
  { a, b = 0, c = 0, d, e = 0, f = 0 }: MatrixTransform,
): TPolygon {
  const transform: Required<MatrixTransform> = { a, b, c, d, e, f };

  return {
    ...polygon,
    points: polygon.points.map(
      <TPoint extends Point>(point: TPoint): TPoint =>
        transformPoint(point, transform),
    ),
  };
}
