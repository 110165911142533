import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent, FC } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventLogFilterLabel,
  EventLogFilterSelect,
} from './event-log-filter-shared';

export type EventLogFilterTimeSpanProps = {
  hourTimeSpan: number | undefined;
  setHourTimeSpan: (timeSpan: number | undefined) => void;
};

export const timeSpanLabels: { value: number; labelKey: string }[] = [
  {
    value: 1,
    labelKey: 'eventLog.filter.timeSpan.lastHour',
  },
  {
    value: 3,
    labelKey: 'eventLog.filter.timeSpan.lastThreeHours',
  },
  {
    value: 24,
    labelKey: 'eventLog.filter.timeSpan.last24Hours',
  },
  {
    value: 7 * 24,
    labelKey: 'eventLog.filter.timeSpan.last7Days',
  },
  {
    value: 15 * 24,
    labelKey: 'eventLog.filter.timeSpan.last15Days',
  },
];

export const EventLogFilterTimeSpan: FC<EventLogFilterTimeSpanProps> = ({
  hourTimeSpan,
  setHourTimeSpan,
}) => {
  const { t } = useTranslation();

  function handleTimeSpanChange(event: ChangeEvent<HTMLInputElement>): void {
    const selectedTimeSpan = Number(event.target.value);
    setHourTimeSpan(selectedTimeSpan);
  }

  const labelId = useId();

  return (
    <>
      <EventLogFilterLabel variant="standard" id={labelId}>
        {t('eventLog.filter.timeSpan.label')}
      </EventLogFilterLabel>
      <EventLogFilterSelect
        value={hourTimeSpan ?? 0}
        onChange={handleTimeSpanChange}
        SelectProps={{ labelId }}
      >
        <MenuItem disabled value={0}>
          {t('eventLog.filter.timeSpan.placeholder')}
        </MenuItem>
        {timeSpanLabels.map((timeSpan) => (
          <MenuItem key={timeSpan.value} value={timeSpan.value}>
            {t(timeSpan.labelKey)}
          </MenuItem>
        ))}
      </EventLogFilterSelect>
    </>
  );
};

export default EventLogFilterTimeSpan;
