import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormattingParamsDefault } from '../../../../../base/date-formatting/date-formatting';

type CardFieldGpsPositionUpdatedProps = {
  value: number | false;
};
const CardFieldGpsPositionUpdated = ({
  value,
}: CardFieldGpsPositionUpdatedProps): JSX.Element => {
  const { t } = useTranslation();

  const lastUpdated = typeof value === 'number' ? value * 1000 : undefined;

  return (
    <span>
      <Typography variant="caption">
        {lastUpdated
          ? t('deviceDetails.position.locationUpdated', {
              lastUpdated,
              formatParams: { lastUpdated: dateTimeFormattingParamsDefault },
            })
          : t('deviceDetails.position.notUpdatedYet')}
      </Typography>
    </span>
  );
};

export default CardFieldGpsPositionUpdated;
