import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldTextProps = {
  name: string;
  value: string | number | boolean;
  icon?: ReactNode;
};

const CardFieldText: FC<CardFieldTextProps> = ({ name, value, icon }) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={name} icon={icon}>
      <Typography>{t(value.toString())}</Typography>
    </CardFieldContent>
  );
};

export default CardFieldText;
