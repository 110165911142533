import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltip from '../../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import CustomerDetailsVerificationChip from './customer-details-verification-chip';

export const FIRST_COLUMN_SIZE = 1;
export const SECOND_COLUMN_SIZE = 2;
export const THIRD_COLUMN_SIZE = 1;
export const FOURTH_COLUMN_SIZE = 3;
export const FIFTH_COLUMN_SIZE = 3;
export const SIXTH_COLUMN_SIZE = 1;

const SmallGridColumn = styled(Grid)`
  flex-basis: 6%;
`;

type CustomerDetailsRowProps = {
  leftIcon?: ReactNode;
  hasMatchingPreferredDealer?: boolean;
  name: string;
  value: string;
  isVerified?: boolean;
  rightIcon?: ReactNode;
  shouldDisplayVerified: boolean;
  hasCustomerAccess?: boolean;
};

const CustomerDetailsRow: FC<CustomerDetailsRowProps> = ({
  leftIcon,
  hasMatchingPreferredDealer = true,
  name,
  value,
  isVerified,
  rightIcon,
  shouldDisplayVerified,
  hasCustomerAccess,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <SmallGridColumn item xs={FIRST_COLUMN_SIZE}>
        <Typography component="div">{leftIcon}</Typography>
      </SmallGridColumn>
      <Grid item xs={SECOND_COLUMN_SIZE}>
        <Typography component="div">
          <b>{name}</b>
        </Typography>
      </Grid>
      <SmallGridColumn item xs={THIRD_COLUMN_SIZE}>
        {!hasCustomerAccess && !hasMatchingPreferredDealer && (
          <StihlTooltip
            title={t('customerDetails.notPreferredDealer')}
            placement="bottom"
            data-testid="notPreferredDealerTooltip"
          >
            <StihlIconInfo />
          </StihlTooltip>
        )}
      </SmallGridColumn>
      <Grid item xs={FOURTH_COLUMN_SIZE}>
        <Typography component="div">{value}</Typography>
      </Grid>
      <Grid item xs={FIFTH_COLUMN_SIZE}>
        {shouldDisplayVerified && (
          <CustomerDetailsVerificationChip isVerified={isVerified} />
        )}
      </Grid>
      <SmallGridColumn item xs={SIXTH_COLUMN_SIZE}>
        <Typography component="div">{rightIcon}</Typography>
      </SmallGridColumn>
    </Grid>
  );
};

export default CustomerDetailsRow;
