import type { Point } from './point';

export type MatrixTransform = {
  a: number;
  b?: number;
  c?: number;
  d: number;
  e?: number;
  f?: number;
};

export function transformPoint<TPoint extends Point>(
  point: TPoint,
  // `e` is not an abbreviation here
  // eslint-disable-next-line unicorn/prevent-abbreviations
  { a, b, c, d, e, f }: Required<MatrixTransform>,
): TPoint {
  return {
    ...point,
    x: a * point.x + c * point.y + e,
    y: b * point.x + d * point.y + f,
  };
}
