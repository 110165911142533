import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary';
import StihlIconTakeOff from '../../../../../base/stihl-material-ui/icons/stihl-icon-takeoff';
import JumpToAppTooltip from './settings-card-jump-to-app-tooltip';

type JumpToAppButtonProps = {
  onClick: () => void;
  customerAppAccess:
    | 'allowed'
    | 'no-customer-data'
    | 'not-preferred-dealer'
    | 'not-possible';
};

const JumpToAppButton: FC<JumpToAppButtonProps> = ({
  onClick,
  customerAppAccess,
}) => {
  const { t } = useTranslation();

  if (customerAppAccess === 'not-possible') return null;

  return (
    <JumpToAppTooltip customerAppAccess={customerAppAccess}>
      <StihlButtonPrimary
        onClick={onClick}
        disabled={customerAppAccess !== 'allowed'}
        data-testid="imowAppAnchorButton"
      >
        <StihlIconTakeOff />
        {t('jumpToApp.iMowApp')}
      </StihlButtonPrimary>
    </JumpToAppTooltip>
  );
};

export default JumpToAppButton;
