import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { Connection } from '../../../model/connection.model';
import { ConnectionDataType } from '../../../model/connection.model';
import ConnectionHistoryPieChartLegend from './connection-history-pie-chart-legend';
import {
  cellularConnectedColor,
  deviceConnectedColor,
  wifiConnectedColor,
} from './connection-history-tooltip';

type ConnectionHistoryPieChartProps = {
  connectionData: Connection[];
  isLineVisible: Record<ConnectionDataType, boolean>;
  startTimestamp: number;
  endTimestamp: number;
};

const ChartsHeading = styled(Typography)`
  margin-block: 2.5rem 1rem;
`;

const ChartsContainer = styled.div`
  display: flex;
  margin-block-start: 2.5rem;
`;

const ChartContainer = styled.div`
  text-align: center;
`;

const ConnectionHistoryPieChartLegendStyled = styled(
  ConnectionHistoryPieChartLegend,
)`
  align-self: center;
`;

const connectionColors: Record<ConnectionDataType, string> = {
  [ConnectionDataType.WifiConnected]: wifiConnectedColor,
  [ConnectionDataType.DeviceConnected]: deviceConnectedColor,
  [ConnectionDataType.CellularConnected]: cellularConnectedColor,
};

function addValueToBucket(
  value: number,
  isConnected: boolean | null,
  buckets: ConnectionBuckets,
): void {
  if (isConnected === true) {
    buckets.connected += value;
  } else if (isConnected === false) {
    buckets.notConnected += value;
  } else {
    buckets.unknown += value;
  }
}

type ConnectionBuckets = {
  connected: number;
  notConnected: number;
  unknown: number;
};

function calculateConnectionBuckets(
  chartData: Connection[],
  startTimestamp: number,
  endTimestamp: number,
): Record<ConnectionDataType, ConnectionBuckets> {
  const result: Record<ConnectionDataType, ConnectionBuckets> = {
    [ConnectionDataType.WifiConnected]: {
      connected: 0,
      notConnected: 0,
      unknown: 0,
    },
    [ConnectionDataType.DeviceConnected]: {
      connected: 0,
      notConnected: 0,
      unknown: 0,
    },
    [ConnectionDataType.CellularConnected]: {
      connected: 0,
      notConnected: 0,
      unknown: 0,
    },
  };

  if (chartData.length === 0) {
    result[ConnectionDataType.WifiConnected].unknown =
      endTimestamp - startTimestamp;
    result[ConnectionDataType.DeviceConnected].unknown =
      endTimestamp - startTimestamp;
    result[ConnectionDataType.CellularConnected].unknown =
      endTimestamp - startTimestamp;

    return result;
  }

  const chartDataCopy = chartData.map<{
    timestamp: number;
    isWifiConnected: boolean | null;
    isDeviceConnected: boolean | null;
    isCellularConnected: boolean | null;
  }>(
    ({
      lastUpdatedTimestamp,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      wifiIsConnected,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      deviceIsCurrentlyConnected,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      cellularIsConnected,
    }) => ({
      timestamp: new Date(lastUpdatedTimestamp).getTime(),
      isWifiConnected: wifiIsConnected,
      isCellularConnected: cellularIsConnected,
      isDeviceConnected: deviceIsCurrentlyConnected,
    }),
  );

  if (chartDataCopy[0].timestamp < startTimestamp) {
    chartDataCopy[0] = {
      ...chartDataCopy[0],
      timestamp: startTimestamp,
    };
  } else {
    chartDataCopy.unshift({
      timestamp: startTimestamp,
      isWifiConnected: null,
      isDeviceConnected: null,
      isCellularConnected: null,
    });
  }

  if (chartDataCopy[chartDataCopy.length - 1].timestamp > endTimestamp) {
    chartDataCopy[chartDataCopy.length - 1] = {
      ...chartDataCopy[chartDataCopy.length - 1],
      timestamp: endTimestamp,
    };
  } else {
    chartDataCopy.push({
      timestamp: endTimestamp,
      isWifiConnected: null,
      isDeviceConnected: null,
      isCellularConnected: null,
    });
  }

  for (let index = 1; index < chartData.length; index++) {
    const valueBefore = chartDataCopy[index - 1];
    const value = chartDataCopy[index];
    const timespan = value.timestamp - valueBefore.timestamp;

    addValueToBucket(
      timespan,
      valueBefore.isWifiConnected,
      result[ConnectionDataType.WifiConnected],
    );

    addValueToBucket(
      timespan,
      valueBefore.isDeviceConnected,
      result[ConnectionDataType.DeviceConnected],
    );

    addValueToBucket(
      timespan,
      valueBefore.isCellularConnected,
      result[ConnectionDataType.CellularConnected],
    );
  }

  return result;
}

const ConnectionHistoryPieChart: FC<ConnectionHistoryPieChartProps> = ({
  connectionData,
  isLineVisible,
  startTimestamp,
  endTimestamp,
}) => {
  const { t, i18n } = useTranslation();

  const connectionPieChartValues = useMemo(() => {
    const connectionBuckets = calculateConnectionBuckets(
      connectionData,
      startTimestamp,
      endTimestamp,
    );

    const toPieChartData = (
      bucket: ConnectionBuckets,
    ): { name: string; value: number }[] => {
      const total = bucket.connected + bucket.notConnected + bucket.unknown;

      return [
        {
          name: t('connectionHistory.connected'),
          value: bucket.connected / total,
        },
        {
          name: t('connectionHistory.notConnected'),
          value: bucket.notConnected / total,
        },
        {
          name: t('connectionHistory.connectionUnknown'),
          value: bucket.unknown / total,
        },
      ];
    };

    return {
      [ConnectionDataType.WifiConnected]: toPieChartData(
        connectionBuckets[ConnectionDataType.WifiConnected],
      ),
      [ConnectionDataType.DeviceConnected]: toPieChartData(
        connectionBuckets[ConnectionDataType.DeviceConnected],
      ),
      [ConnectionDataType.CellularConnected]: toPieChartData(
        connectionBuckets[ConnectionDataType.CellularConnected],
      ),
    };
  }, [connectionData, startTimestamp, endTimestamp, t]);

  const formatNumber = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: 'percent',
        maximumFractionDigits: 1,
      }),
    [i18n.language],
  );

  const formatTooltip = useCallback(
    (value: number) => formatNumber.format(value),
    [formatNumber],
  );

  const chartLabel: Record<ConnectionDataType, string> = {
    [ConnectionDataType.WifiConnected]: t('connectionHistory.wifiConnected'),
    [ConnectionDataType.DeviceConnected]: t(
      'connectionHistory.deviceConnected',
    ),
    [ConnectionDataType.CellularConnected]: t(
      'connectionHistory.mobileConnection',
    ),
  };

  if (
    !isLineVisible[ConnectionDataType.WifiConnected] &&
    !isLineVisible[ConnectionDataType.DeviceConnected] &&
    !isLineVisible[ConnectionDataType.CellularConnected]
  ) {
    return null;
  }

  return (
    <>
      <ChartsHeading variant="h3">
        {t('connectionHistory.pieChartsHeading')}
      </ChartsHeading>
      <ChartsContainer>
        {Object.values(ConnectionDataType).map((connectionDataType) =>
          isLineVisible[connectionDataType] ? (
            <ChartContainer key={connectionDataType}>
              <Typography variant="h3" component="h4">
                {chartLabel[connectionDataType]}
              </Typography>
              <PieChart
                width={320}
                height={320}
                title={chartLabel[connectionDataType]}
                role="graphics-document"
              >
                <Pie
                  data={connectionPieChartValues[connectionDataType]}
                  dataKey="value"
                >
                  <Cell fill={connectionColors[connectionDataType]} />
                  <Cell fill={stihlColor.greyBase} />
                  <Cell fill={stihlColor.greyLight} />
                </Pie>
                <Tooltip formatter={formatTooltip} />
              </PieChart>
            </ChartContainer>
          ) : null,
        )}
        <ConnectionHistoryPieChartLegendStyled isLineVisible={isLineVisible} />
      </ChartsContainer>
    </>
  );
};

export default ConnectionHistoryPieChart;
