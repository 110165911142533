import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { StihlIconInfo } from '../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { useRemoveDeputy } from '../../service/customer-service/customer-service';

export type CustomerManagementDeputyRemoveProps = {
  name: string;
  id: string;
  subscriptionId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CustomerManagementDeputyRemove: FC<
  CustomerManagementDeputyRemoveProps
> = ({ name, id: deputyId, subscriptionId, open, setOpen }) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const removeDeputyMutation = useRemoveDeputy();

  function handleClose(): void {
    setOpen(false);
  }

  const handleRemoveDeputy = useCallback(() => {
    removeDeputyMutation.mutate({ deputyId, subscriptionId });
  }, [deputyId, subscriptionId, removeDeputyMutation]);

  useEffect(() => {
    if (removeDeputyMutation.isSuccess) {
      setOpen(false);
      setAlert({
        isOpen: true,
        severity: 'success',
        message: t('customerManagement.successfulRemoveDeputy'),
      });
    } else if (removeDeputyMutation.isError) {
      setOpen(false);
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('customerManagement.failedRemoveDeputy'),
      });
    }
  }, [
    removeDeputyMutation.isSuccess,
    removeDeputyMutation.isError,
    setAlert,
    setOpen,
    t,
  ]);

  return (
    <StihlModal
      open={open}
      onClose={handleClose}
      isLoading={removeDeputyMutation.isPending}
      title={t('customerManagement.removeDeputy', { deputyName: name })}
      actionButtonText={t('remove')}
      handleActionClick={handleRemoveDeputy}
    >
      <>
        <Typography>
          <StihlIconInfo />
          <Typography
            variant="h5"
            component="span"
            sx={{ marginLeft: '0.5rem' }}
          >
            {t('customerManagement.removeDeputyAlert')}
          </Typography>
        </Typography>
        <br />
        <Typography>{t('customerManagement.removeDeputyHint')}</Typography>
      </>
    </StihlModal>
  );
};
