import type {
  CardField,
  CardFieldCommand,
  CardFieldDeviceCellularNetworkInformation,
} from './card-field.model';
import type { DeviceModel } from './device.model';

export const CardType = {
  Card: 'Card',
  DeviceStatus: 'DeviceStatus',
  CustomerDetails: 'CustomerDetails',
  Settings: 'Settings',
  EventLog: 'EventLog',
  Logs: 'Logs',
  ChangeEnvironment: 'ChangeEnvironment',
  Flags: 'Flags',
  Commands: 'Commands',
  BasicDeviceInformation: 'BasicDeviceInformation',
  DemoMode: 'DemoMode',
  Search: 'Search',
  SearchResult: 'SearchResult',
  DirectHomeDrive: 'DirectHomeDrive',
  OperationData: 'OperationData',
  ProductionAndIdentificationData: 'ProductionAndIdentificationData',
  DiagnosisData: 'DiagnosisData',
  DiagnosisDetailsData: 'DiagnosisDetailsData',
  DtcCodes: 'DtcCodes',
  QualityOfMobileConnection: 'QualityOfMobileConnection',
  ConnectionHistory: 'ConnectionHistory',
  CertificateStatus: 'CertificateStatus',
  Fleetmanagement: 'Fleetmanagement',
} as const;

export type CardType = (typeof CardType)[keyof typeof CardType];

export type GenericCard = {
  title: string;
  type: 'Card' | 'CustomerDetails' | 'SearchResult';
  cardFields: CardField[];
  isFullWidth?: boolean;
};

type CardWithTitle<TType extends CardType> = {
  type: TType;
  title: string;
};

type DeviceSearchCard = {
  type: 'Search';
};

export type DeviceStatusCard = {
  type: 'DeviceStatus';
  cardTabs: CardTab[];
};

export type FleetManagementCard = {
  type: 'Fleetmanagement';
  title: string;
  cardFields: CardField[];
};

export type SettingsCard = {
  type: 'Settings';
  title: string;
  customerAppAccess:
    | 'allowed'
    | 'no-customer-data'
    | 'not-preferred-dealer'
    | 'not-possible';
  cardFields: CardField[];
};

export type LogsCard = CardWithTitle<'Logs'>;
export type ChangeEnvironmentCard = CardWithTitle<'ChangeEnvironment'>;
export type FlagsCard = CardWithTitle<'Flags'>;

export type CommandsCard = {
  type: 'Commands';
  title: string;
  cardFields: CardFieldCommand[];
};

export type DemoModeCard = {
  type: 'DemoMode';
  title: string;
  cardFields: [{ value: boolean }];
};

export type OperationDataCard = {
  type: 'OperationData';
  title: string;
  cardFields: CardField[];
};

export type DirectHomeDriveCard = {
  type: 'DirectHomeDrive';
  cardFields: [{ value: boolean }];
};

export type ProductionAndIdentificationDataCard = {
  type: 'ProductionAndIdentificationData';
  title: string;
  cardFields: CardField[];
};

export type DiagnosisDataCard = CardWithTitle<'DiagnosisData'>;

type DiagnosisDetailsDataCard = {
  type: 'DiagnosisDetailsData';
};

export type CertificateStatusCard = CardWithTitle<'CertificateStatus'>;

export type EventLogCard = {
  type: 'EventLog';
  title: string;
  cardFields: CardField[];
};

type DtcCodesCard = {
  type: 'DtcCodes';
  cardFields: [{ value: string[] }];
};

export type QualityOfMobileConnectionCard = {
  type: 'QualityOfMobileConnection';
  title: string;
  cardFields: [CardFieldDeviceCellularNetworkInformation];
};

export type ConnectionHistoryCard = {
  type: 'ConnectionHistory';
  title: string;
  deviceModel: DeviceModel;
  deviceId: string;
  cardFields: CardField[];
};

export type CardTab = {
  title: string;
  cardFields: CardField[];
};

export type Card =
  | GenericCard
  | DeviceSearchCard
  | DeviceStatusCard
  | FleetManagementCard
  | SettingsCard
  | LogsCard
  | ChangeEnvironmentCard
  | FlagsCard
  | CommandsCard
  | DemoModeCard
  | OperationDataCard
  | DirectHomeDriveCard
  | ProductionAndIdentificationDataCard
  | DiagnosisDataCard
  | DiagnosisDetailsDataCard
  | CertificateStatusCard
  | EventLogCard
  | DtcCodesCard
  | QualityOfMobileConnectionCard
  | ConnectionHistoryCard;
