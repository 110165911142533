import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconValidFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { CertificateStatus } from '../../../model';
import { DeviceModel } from '../../../model';

const StatusContainer = styled.div`
  margin-block: 0.5rem 3rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  display: inline;
  margin-inline-start: 0.5rem;
`;

export const Info = ({ text }: { text: string }): JSX.Element => {
  return (
    <InfoContainer>
      <StihlIconInfo />
      <StyledTypography variant="h5">{text}</StyledTypography>
    </InfoContainer>
  );
};

const Status = ({
  icon,
  statusText,
  infoText,
}: {
  icon: ReactNode;
  statusText: string;
  infoText?: string;
}): JSX.Element => {
  return (
    <StatusContainer>
      <div>
        {icon}
        <StyledTypography>{statusText}</StyledTypography>
      </div>
      {!!infoText && <Info text={infoText} />}
    </StatusContainer>
  );
};

export const CommunicationCertificateStatus = ({
  certificateStatus,
  deviceModel,
}: {
  certificateStatus: CertificateStatus;
  deviceModel: DeviceModel;
}): JSX.Element => {
  const { toLocaleDateString } = useDateFormatting();

  const {
    lifecycleValidFrom,
    lifecycleValidTo,
    birthValidFrom,
    birthValidTo,
    birthIssuer,
  } = useMemo(() => {
    return {
      lifecycleValidFrom: certificateStatus.lifecycleValidFrom
        ? toLocaleDateString(new Date(certificateStatus.lifecycleValidFrom))
        : '',
      lifecycleValidTo: certificateStatus.lifecycleValidTo
        ? toLocaleDateString(new Date(certificateStatus.lifecycleValidTo))
        : '',
      birthValidFrom: certificateStatus.birthValidFrom
        ? toLocaleDateString(new Date(certificateStatus.birthValidFrom))
        : '',
      birthValidTo: certificateStatus.birthValidTo
        ? toLocaleDateString(new Date(certificateStatus.birthValidTo))
        : '',
      birthIssuer: certificateStatus.birthIssuer,
    };
  }, [
    certificateStatus.birthIssuer,
    certificateStatus.birthValidFrom,
    certificateStatus.birthValidTo,
    certificateStatus.lifecycleValidFrom,
    certificateStatus.lifecycleValidTo,
    toLocaleDateString,
  ]);

  if (deviceModel === DeviceModel.connectedBox) {
    return (
      <>
        <BirthCertificateStatus
          formattedBirthValidFrom={birthValidFrom}
          formattedBirthValidTo={birthValidTo}
          birthValidFrom={certificateStatus.birthValidFrom}
          birthValidTo={certificateStatus.birthValidTo}
          birthIssuer={birthIssuer}
          deviceModel={deviceModel}
        />
        <LifecycleCertificateStatus
          formattedLifecycleValidFrom={lifecycleValidFrom}
          formattedLifecycleValidTo={lifecycleValidTo}
          lifecycleValidFrom={certificateStatus.lifecycleValidFrom}
          lifecycleValidTo={certificateStatus.lifecycleValidTo}
        />
      </>
    );
  }

  if (
    certificateStatus.lifecycleValidTo &&
    certificateStatus.lifecycleValidFrom
  ) {
    return (
      <LifecycleCertificateStatus
        formattedLifecycleValidFrom={lifecycleValidFrom}
        formattedLifecycleValidTo={lifecycleValidTo}
        lifecycleValidFrom={certificateStatus.lifecycleValidFrom}
        lifecycleValidTo={certificateStatus.lifecycleValidTo}
      />
    );
  }

  return (
    <BirthCertificateStatus
      formattedBirthValidFrom={birthValidFrom}
      formattedBirthValidTo={birthValidTo}
      birthValidFrom={certificateStatus.birthValidFrom}
      birthValidTo={certificateStatus.birthValidTo}
      birthIssuer={birthIssuer}
      deviceModel={deviceModel}
    />
  );
};

const BirthCertificateStatus = ({
  formattedBirthValidFrom,
  formattedBirthValidTo,
  birthValidFrom,
  birthValidTo,
  birthIssuer,
  deviceModel,
}: {
  formattedBirthValidFrom: string;
  formattedBirthValidTo: string;
  birthValidFrom: number | undefined;
  birthValidTo: number | undefined;
  birthIssuer: string | undefined;
  deviceModel: DeviceModel;
}): JSX.Element => {
  const { t } = useTranslation();

  if (!birthValidFrom || !birthValidTo) {
    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.noBirth',
        )}
        infoText={t(
          'certificateStatus.communicationCertificateSection.noValidBirthInfo',
        )}
      />
    );
  }

  if (birthValidFrom < Date.now() && birthValidTo > Date.now()) {
    return (
      <Status
        icon={<StihlIconValidFilled color="success" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.birthValid',
          {
            validFrom: formattedBirthValidFrom,
            validTo: formattedBirthValidTo,
            issuer: birthIssuer,
          },
        )}
        infoText={
          deviceModel === DeviceModel.connectedBox
            ? undefined
            : t(
                'certificateStatus.communicationCertificateSection.noLifecycleInfo',
              )
        }
      />
    );
  }

  return (
    <Status
      icon={<StihlIconXFilled color="error" />}
      statusText={t(
        'certificateStatus.communicationCertificateSection.birthInvalid',
        {
          issuer: birthIssuer,
        },
      )}
      infoText={t(
        'certificateStatus.communicationCertificateSection.noValidBirthInfo',
      )}
    />
  );
};

const LifecycleCertificateStatus = ({
  formattedLifecycleValidFrom,
  formattedLifecycleValidTo,
  lifecycleValidFrom,
  lifecycleValidTo,
}: {
  formattedLifecycleValidFrom: string;
  formattedLifecycleValidTo: string;
  lifecycleValidFrom: number | undefined;
  lifecycleValidTo: number | undefined;
}): JSX.Element => {
  const { t } = useTranslation();

  if (!lifecycleValidFrom || !lifecycleValidTo) {
    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.noLifecycle',
        )}
        infoText={t(
          'certificateStatus.communicationCertificateSection.noLifecycleInfo',
        )}
      />
    );
  }

  if (lifecycleValidFrom < Date.now() && lifecycleValidTo > Date.now()) {
    return (
      <Status
        icon={<StihlIconValidFilled color="success" />}
        statusText={t(
          'certificateStatus.communicationCertificateSection.lifecycleValid',
          {
            validFrom: formattedLifecycleValidFrom,
            validTo: formattedLifecycleValidTo,
          },
        )}
        infoText={t(
          'certificateStatus.communicationCertificateSection.lifecycleValidInfo',
        )}
      />
    );
  }

  return (
    <Status
      icon={<StihlIconXFilled color="error" />}
      statusText={t(
        'certificateStatus.communicationCertificateSection.lifecycleInvalid',
        {
          validTo: formattedLifecycleValidTo,
        },
      )}
      infoText={t(
        'certificateStatus.communicationCertificateSection.lifecycleInvalidInfo',
      )}
    />
  );
};

export const DiagnosisCertificateStatus = ({
  certificateStatus,
}: {
  certificateStatus: CertificateStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();

  const { diagnosisValidFrom, diagnosisValidTo } = useMemo(() => {
    return {
      diagnosisValidFrom: certificateStatus.diagnosisValidFrom
        ? toLocaleDateString(new Date(certificateStatus.diagnosisValidFrom))
        : '',
      diagnosisValidTo: certificateStatus.diagnosisValidTo
        ? toLocaleDateString(new Date(certificateStatus.diagnosisValidTo))
        : '',
    };
  }, [
    certificateStatus.diagnosisValidFrom,
    certificateStatus.diagnosisValidTo,
    toLocaleDateString,
  ]);

  if (
    certificateStatus.diagnosisValidTo &&
    certificateStatus.diagnosisValidFrom
  ) {
    if (
      certificateStatus.diagnosisValidFrom < Date.now() &&
      certificateStatus.diagnosisValidTo > Date.now()
    ) {
      return (
        <Status
          icon={<StihlIconValidFilled color="success" />}
          statusText={t('certificateStatus.diagnosisCertificateSection.valid', {
            validFrom: diagnosisValidFrom,
            validTo: diagnosisValidTo,
          })}
        />
      );
    }

    return (
      <Status
        icon={<StihlIconXFilled color="error" />}
        statusText={t(
          'certificateStatus.diagnosisCertificateSection.wasValid',
          {
            validFrom: diagnosisValidFrom,
            validTo: diagnosisValidTo,
          },
        )}
      />
    );
  }

  return (
    <StatusContainer>
      <div>
        <StihlIconXFilled color={stihlColor.greyBase} />
        <StyledTypography>
          {t('certificateStatus.diagnosisCertificateSection.notExisting')}
        </StyledTypography>
      </div>
    </StatusContainer>
  );
};
