import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import type { CertificateStatus, DeviceModel } from '../../../model';
import {
  CommunicationCertificateStatus,
  DiagnosisCertificateStatus,
  Info,
} from './certificate-status';

const Subheader = styled(Typography)`
  margin-block-end: 0.5rem;
`;

export const CommunicationCertificateStatusSection = ({
  certificateStatus,
  deviceModel,
}: {
  certificateStatus: CertificateStatus;
  deviceModel: DeviceModel;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Subheader variant="h3">
        {t('certificateStatus.communicationCertificateSection.title')}
      </Subheader>
      <Info
        text={t(
          'certificateStatus.communicationCertificateSection.generalInfo',
        )}
      />
      <CommunicationCertificateStatus
        certificateStatus={certificateStatus}
        deviceModel={deviceModel}
      />
    </div>
  );
};

export const DiagnosisCertificateStatusSection = ({
  certificateStatus,
}: {
  certificateStatus: CertificateStatus;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Subheader variant="h3">
        {t('certificateStatus.diagnosisCertificateSection.title')}
      </Subheader>
      <Info
        text={t('certificateStatus.diagnosisCertificateSection.generalInfo')}
      />
      <DiagnosisCertificateStatus certificateStatus={certificateStatus} />
    </div>
  );
};
