import styled from '@emotion/styled';
import type { CardProps } from '@mui/material/Card';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { FC, JSX, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { GenericCard as GenericCardValue } from '../../../model/card.model';
import CardField from '../../card-field/card-field';

export const StyledCardContent = styled(CardContent)`
  block-size: 100%;
  padding: 1rem;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

export const ContentWrapper = styled.div`
  gap: 2rem;
`;

const StyledCard = styled(
  ({
    isFullWidth: _IsFullWidth,
    isFullHeight: _IsFullHeight,
    ...rest
  }: CardProps & { isFullWidth: boolean; isFullHeight: boolean }) => (
    <MuiCard {...rest} />
  ),
)`
  inline-size: ${(props) => (props.isFullWidth ? '100%' : '50%')};
  block-size: ${(props) => (props.isFullHeight ? '100%' : 'auto')};

  @media screen and (max-width: 1200px) {
    inline-size: 100%;
  }
`;

export const CardBase = ({
  title,
  isFullHeight = false,
  isFullWidth = true,
  headerElement,
  children,
  ...rest
}: {
  title: string;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  headerElement?: ReactNode;
  children: ReactNode;
} & CardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledCard isFullHeight={isFullHeight} isFullWidth={isFullWidth} {...rest}>
      <CardHeader>
        <Typography variant="h3" component="h2">
          {t(title)}
        </Typography>
        {headerElement !== undefined && headerElement}
      </CardHeader>
      <StyledCardContent>{children}</StyledCardContent>
    </StyledCard>
  );
};

export type GenericCardProps<TCard> = {
  cardData: TCard;
};

const GenericCard: FC<GenericCardProps<GenericCardValue>> = ({ cardData }) => {
  return cardData.cardFields.length > 0 ? (
    <CardBase title={cardData.title} isFullWidth={cardData.isFullWidth}>
      {cardData.cardFields.map((data) => (
        <ContentWrapper key={data.title}>
          <CardField value={data} />
        </ContentWrapper>
      ))}
    </CardBase>
  ) : null;
};
export default GenericCard;
