import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsBg from './locales/bg-BG/translations.json';
import dtcCs from './locales/cs-CZ/dtc.json';
import translationsCs from './locales/cs-CZ/translations.json';
import dtcDa from './locales/da-DK/dtc.json';
import translationsDa from './locales/da-DK/translations.json';
import dtcDe from './locales/de/dtc.json';
import translationsDe from './locales/de/translations.json';
// eslint-disable-next-line unicorn/prevent-abbreviations
import dtcEl from './locales/el-GR/dtc.json';
// eslint-disable-next-line unicorn/prevent-abbreviations
import translationsEl from './locales/el-GR/translations.json';
import dtcEn from './locales/en/dtc.json';
import translationsEn from './locales/en/translations.json';
import dtcEs from './locales/es-ES/dtc.json';
import translationsEs from './locales/es-ES/translations.json';
import translationsEt from './locales/et-EE/translations.json';
import dtcFi from './locales/fi-FI/dtc.json';
import translationsFi from './locales/fi-FI/translations.json';
import dtcFr from './locales/fr-FR/dtc.json';
import translationsFr from './locales/fr-FR/translations.json';
import translationsHr from './locales/hr-HR/translations.json';
import dtcHr from './locales/hu-HU/dtc.json';
import translationsHu from './locales/hu-HU/translations.json';
import dtcIt from './locales/it-IT/dtc.json';
import translationsIt from './locales/it-IT/translations.json';
import translationsLt from './locales/lt-LT/translations.json';
import translationsLv from './locales/lv-LV/translations.json';
import dtcNo from './locales/nb-NO/dtc.json';
import translationsNb from './locales/nb-NO/translations.json';
import dtcNl from './locales/nl-NL/dtc.json';
import translationsNl from './locales/nl-NL/translations.json';
import dtcPl from './locales/pl-PL/dtc.json';
import translationsPl from './locales/pl-PL/translations.json';
import dtcPt from './locales/pt-PT/dtc.json';
import translationsPt from './locales/pt-PT/translations.json';
import dtcRo from './locales/ro-RO/dtc.json';
import translationsRo from './locales/ro-RO/translations.json';
import dtcSk from './locales/sk-SK/dtc.json';
import translationsSk from './locales/sk-SK/translations.json';
import translationsSl from './locales/sl-SI/translations.json';
import translationsSr from './locales/sr-RS/translations.json';
import dtcSv from './locales/sv-SE/dtc.json';
import translationsSv from './locales/sv-SE/translations.json';

void use(initReactI18next).init({
  resources: {
    'en-US': { translation: translationsEn, dtc: dtcEn },
    'de-DE': { translation: translationsDe, dtc: dtcDe },
    'fr-FR': { translation: translationsFr, dtc: dtcFr },
    'it-IT': { translation: translationsIt, dtc: dtcIt },
    'cs-CZ': { translation: translationsCs, dtc: dtcCs },
    'bg-BG': { translation: translationsBg, dtc: dtcEn },
    'da-DK': { translation: translationsDa, dtc: dtcDa },
    'el-GR': { translation: translationsEl, dtc: dtcEl },
    'fi-FI': { translation: translationsFi, dtc: dtcFi },
    'es-ES': { translation: translationsEs, dtc: dtcEs },
    'et-EE': { translation: translationsEt, dtc: dtcEn },
    'hr-HR': { translation: translationsHr, dtc: dtcEn },
    'hu-HU': { translation: translationsHu, dtc: dtcHr },
    'lt-LT': { translation: translationsLt, dtc: dtcEn },
    'lv-LV': { translation: translationsLv, dtc: dtcEn },
    'nb-NO': { translation: translationsNb, dtc: dtcNo },
    'nl-NL': { translation: translationsNl, dtc: dtcNl },
    'pl-PL': { translation: translationsPl, dtc: dtcPl },
    'pt-PT': { translation: translationsPt, dtc: dtcPt },
    'ro-RO': { translation: translationsRo, dtc: dtcRo },
    'sk-SK': { translation: translationsSk, dtc: dtcSk },
    'sl-SI': { translation: translationsSl, dtc: dtcEn },
    'sr-RS': { translation: translationsSr, dtc: dtcEn },
    'sv-SE': { translation: translationsSv, dtc: dtcSv },
  },
  lng: window.localStorage.getItem('chosenLanguage') ?? 'en-US',
  fallbackLng: 'en-US',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interpolation: { escapeValue: false },
});

// eslint-disable-next-line jest/require-hook
i18n.on('languageChanged', (language) => {
  window.localStorage.setItem('chosenLanguage', language);
});

// eslint-disable-next-line import/max-dependencies
export { default } from 'i18next';
