// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v3.19.1
// source: src/modules/device/ui/card-field/card-field-gnss-working-area-map/working-area-collection.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "";

export interface PolygonPoint {
  x: number;
  y: number;
  subsequentEdgeState: PolygonPoint_PolygonEdgeState;
}

export const PolygonPoint_PolygonEdgeState = {
  SAFETY_UNVALIDATED: 0,
  SAFETY_VALIDATED: 1,
  SAFETY_IRRELEVANT: 2,
} as const;

export type PolygonPoint_PolygonEdgeState =
  typeof PolygonPoint_PolygonEdgeState[keyof typeof PolygonPoint_PolygonEdgeState];

export namespace PolygonPoint_PolygonEdgeState {
  export type SAFETY_UNVALIDATED = typeof PolygonPoint_PolygonEdgeState.SAFETY_UNVALIDATED;
  export type SAFETY_VALIDATED = typeof PolygonPoint_PolygonEdgeState.SAFETY_VALIDATED;
  export type SAFETY_IRRELEVANT = typeof PolygonPoint_PolygonEdgeState.SAFETY_IRRELEVANT;
}

export interface Polygon {
  polygonId: number;
  type: Polygon_PolygonType;
  /** Shall include at least 1 point */
  points: PolygonPoint[];
}

export const Polygon_PolygonType = { INCLUSIVE: 0, EXCLUSIVE: 1 } as const;

export type Polygon_PolygonType = typeof Polygon_PolygonType[keyof typeof Polygon_PolygonType];

export namespace Polygon_PolygonType {
  export type INCLUSIVE = typeof Polygon_PolygonType.INCLUSIVE;
  export type EXCLUSIVE = typeof Polygon_PolygonType.EXCLUSIVE;
}

export interface WorkingAreaGeometry {
  workingAreaId: number;
  version: number;
  /**
   * 0-51, 1 with PolygonType==INCLUSIVE, 0-50 with PolygonType==EXCLUSIVE
   * Shall include at least 1 polygon
   */
  polygons: Polygon[];
}

export interface WorkingAreaCollection {
  geometries: WorkingAreaGeometry[];
}

function createBasePolygonPoint(): PolygonPoint {
  return { x: 0, y: 0, subsequentEdgeState: 0 };
}

export const PolygonPoint: MessageFns<PolygonPoint> = {
  encode(message: PolygonPoint, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.x !== 0) {
      writer.uint32(13).float(message.x);
    }
    if (message.y !== 0) {
      writer.uint32(21).float(message.y);
    }
    if (message.subsequentEdgeState !== 0) {
      writer.uint32(24).int32(message.subsequentEdgeState);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PolygonPoint {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolygonPoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 13) {
            break;
          }

          message.x = reader.float();
          continue;
        }
        case 2: {
          if (tag !== 21) {
            break;
          }

          message.y = reader.float();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.subsequentEdgeState = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBasePolygon(): Polygon {
  return { polygonId: 0, type: 0, points: [] };
}

export const Polygon: MessageFns<Polygon> = {
  encode(message: Polygon, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.polygonId !== 0) {
      writer.uint32(8).uint32(message.polygonId);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    for (const v of message.points) {
      PolygonPoint.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Polygon {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolygon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.polygonId = reader.uint32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.points.push(PolygonPoint.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseWorkingAreaGeometry(): WorkingAreaGeometry {
  return { workingAreaId: 0, version: 0, polygons: [] };
}

export const WorkingAreaGeometry: MessageFns<WorkingAreaGeometry> = {
  encode(message: WorkingAreaGeometry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.workingAreaId !== 0) {
      writer.uint32(8).uint32(message.workingAreaId);
    }
    if (message.version !== 0) {
      writer.uint32(16).uint32(message.version);
    }
    for (const v of message.polygons) {
      Polygon.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkingAreaGeometry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkingAreaGeometry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.workingAreaId = reader.uint32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.version = reader.uint32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.polygons.push(Polygon.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

function createBaseWorkingAreaCollection(): WorkingAreaCollection {
  return { geometries: [] };
}

export const WorkingAreaCollection: MessageFns<WorkingAreaCollection> = {
  encode(message: WorkingAreaCollection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.geometries) {
      WorkingAreaGeometry.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WorkingAreaCollection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWorkingAreaCollection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.geometries.push(WorkingAreaGeometry.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },
};

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
}
