import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlIconMowerLink from '../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { DeviceModel } from '../../device/model/device.model';
import { SearchParameterValues } from '../../device/model/search.model';
import { CardBase } from '../../device/ui/card/generic-card/generic-card';

const StyledHeadingTableCell = styled(TableCell)`
  color: ${stihlColor.fontGrey};
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

const StyledBodyTableCell = styled(TableCell)`
  inline-size: 25%;
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

const StyledBodyTableCellPrimary = styled(StyledBodyTableCell)`
  font-weight: 600;
`;

const OrganizationSearchResultList = ({
  deviceIds,
}: {
  deviceIds: string[];
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  function handleOpenDevice(deviceId: string): void {
    history.push('/device', {
      selector: SearchParameterValues.DeviceId,
      search: deviceId,
      deviceModel: DeviceModel.iMowIA01,
    });
  }

  return (
    <CardBase
      title="searchResultTable.searchTitle"
      data-testid="organizationSearchResultList"
    >
      <Table sx={{ width: 'max-content' }}>
        <TableHead>
          <TableRow>
            <StyledHeadingTableCell>
              {t('searchResultTable.headers.deviceId')}
            </StyledHeadingTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceIds.map((deviceId) => (
            <TableRow
              key={deviceId}
              style={{ cursor: 'pointer' }}
              hover
              data-testid="tableRow"
              onClick={() => {
                handleOpenDevice(deviceId);
              }}
            >
              <StyledBodyTableCellPrimary>
                {deviceId}
              </StyledBodyTableCellPrimary>
              <StyledBodyTableCell align="right">
                <StihlIconMowerLink color="stihlOrange" />
              </StyledBodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardBase>
  );
};

export default OrganizationSearchResultList;
