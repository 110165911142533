import type { ChipType } from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { ErrorValue } from '../../../../device/model/card-field.model';
import type { ManagedDevice } from '../../../model/overview.models';
import type { DeviceStatus } from '../../../model/statistics.model';

const toCell = (value: string, enclosingCharacter: string): string =>
  `${enclosingCharacter}${value.toString()}${enclosingCharacter}`;

const toCsvRow = (
  row:
    | ManagedDevice
    | Omit<DeviceStatus, 'errors'>
    | (Omit<DeviceStatus, 'errors'> & ErrorValue),
  enclosingCharacter: string,
  separator: string,
): string => {
  return Object.values(row)
    .map((column) =>
      // eslint-disable-next-line regexp/no-useless-flag
      toCell(JSON.stringify(column).replaceAll(/"/gmu, ''), enclosingCharacter),
    )
    .join(separator);
};

const toHeaderAndContent = (
  mappedData:
    | Omit<DeviceStatus, 'errors'>[]
    | (Omit<DeviceStatus, 'errors'> & ErrorValue)[],
  enclosingCharacter: string,
  separator: string,
): string => {
  const headerData = Object.keys(mappedData[0])
    .map((cell) => toCell(cell, enclosingCharacter))
    .join(separator);

  const content = mappedData
    .map((row) => toCsvRow(row, enclosingCharacter, separator))
    .join(`\n`);
  return `${headerData}\n${content}`;
};

export const createCsvFromManagementData = (
  data: ManagedDevice[],
  chips: ChipType[],
): string => {
  const enclosingCharacter = '"';
  const separator = ';';

  let headerData = Object.keys(data[0])
    .map((cell) => toCell(cell, enclosingCharacter))
    .join(separator);

  if (chips.length > 0) {
    const filter = `"${chips
      .map((chip) => Object.values(chip).join(': '))
      .join(', ')}"`;
    headerData = `${filter}\n\n${headerData}`;
  }

  const content = data
    .map((row) => toCsvRow(row, enclosingCharacter, separator))
    .join(`\n`);

  return `${headerData}\n${content}`;
};

export const createCsvFromErrorStatusData = (data: DeviceStatus[]): string => {
  const enclosingCharacter = '"';
  const separator = ';';

  // eslint-disable-next-line unicorn/no-array-reduce
  const mappedData = data.reduce(
    (
      accumulator: (Omit<DeviceStatus, 'errors'> & ErrorValue)[],
      current: DeviceStatus,
    ) => {
      for (const error of current.errors) {
        const { errors: _, ...rest } = current;
        accumulator.push({ ...rest, ...error });
      }
      return accumulator;
    },
    [],
  );

  return toHeaderAndContent(mappedData, enclosingCharacter, separator);
};

export const createCsvFromStatusData = (data: DeviceStatus[]): string => {
  const enclosingCharacter = '"';
  const separator = ';';

  const mappedData = data.map((deviceStatus) => {
    const { errors: _, ...rest } = deviceStatus;
    return rest;
  });

  return toHeaderAndContent(mappedData, enclosingCharacter, separator);
};

export const downloadCsv = (fileName: string, csvData: string): void => {
  const element = document.createElement('a');
  const file = new Blob([csvData], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}-${new Date().toISOString()}.csv`;
  document.body.append(element);
  element.click();
  element.remove();
};
