/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import type { QueryCache } from '@tanstack/react-query';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import StihlSnackbarAlert from '../base/stihl-material-ui/components/stihl-snackbar-alert/stihl-snackbar-alert';
import { StihlMaterialThemeProvider } from '../base/stihl-material-ui/theme/stihl-theme-provider';
import { useAlertStore } from '../modules/app-alert/service/alert-provider';
import { useAxiosInterceptors } from '../modules/app-authentication/service/authentication-interceptor';
import { getUser } from '../modules/app-authentication/service/authentication-session-service';
import {
  LoginProvider,
  sessionUserFromLogin,
} from '../modules/app-authentication/service/authentication-session-store';
import { useStore } from '../modules/app-authentication/service/authentication-store-provider';
import type { DealerDetails } from '../modules/app-shell/model/app.models';
import { NavigationItem } from '../modules/app-shell/model/app.models';
import AppErrorBoundary from '../modules/app-shell/ui/error-boundary/app-error-boundary';
import CustomerAccountRegistrationForm from '../modules/customer-management/ui/customer-registration/customer-account-registration';
import { CustomerManagementTabsComponent } from '../modules/customer-management/ui/tabs/customer-management-tabs-component';
import { AccessLicenses } from '../modules/device/model';
import DeviceDetails from '../modules/device/ui/device-details/device-details';
import DeviceManagementTabsComponent from '../modules/device-management/ui/tabs/device-management-tabs-component';
import { HelpTabsComponent } from '../modules/help/ui/help-tabs-component';
import LoginPage from '../modules/login/ui/login-page';
import TermsAndConditionsModal from '../modules/login/ui/terms-and-conditions-modal';
import SystemHealthOverview from '../modules/system-health/ui/system-health-overview';
import AppMain from './app-main';
import {
  getAppBase,
  getDealerAccountDetails,
} from './service/app-main-api-client';
// eslint-disable-next-line import/max-dependencies
import { handleQueryError } from './utils/query-utils';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100vw;
  block-size: 100vh;
`;
export const App = ({
  queryCache,
}: {
  queryCache: QueryCache;
}): JSX.Element => {
  const [session, setSession] = useStore();

  const [alert, setAlert] = useAlertStore();

  const [isLoading, setIsLoading] = useState(true);

  const [isPageLoadFinished, setIsPageLoadFinished] = useState(false);
  const [dealerDetails, setDealerDetails] = useState<DealerDetails>({
    postalCode: '',
    city: '',
    houseNo: '',
    street: '',
    dealerCompanyName: '',
    locale: '',
  });

  useAxiosInterceptors();

  queryCache.config = {
    onError: (error) => {
      handleQueryError(error, setAlert);
    },
  };

  useEffect(() => {
    getUser()
      .then((user) => {
        if (user) {
          setSession((currentSession) => ({
            ...currentSession,
            ...sessionUserFromLogin(user),
            authType: LoginProvider.DealerWebSso,
          }));
          setIsLoading(false);
        }
        return user;
      })
      .catch((error) => console.log(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isLoading) {
      return;
    }
    if (session.token) {
      getAppBase()
        .then((baseResponse) => {
          if (!isMounted) {
            return;
          }
          setSession((currentSession) => ({
            ...currentSession,
            ...baseResponse,
          }));
          return setIsPageLoadFinished(true);
        })
        .catch(() => {
          console.log('error');
        });
    }
    if (session.dealerId) {
      getDealerAccountDetails(session.dealerId)
        .then((response) => {
          setDealerDetails(response);
          return true;
        })
        .catch((error: Error) => {
          console.error('Could not retrieve dealer details', error);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const loginPath = new URL(process.env.REACT_APP_AUTH_REDIRECT_URI as string)
    .pathname;

  if (!session.token) {
    return (
      <AppErrorBoundary>
        <StihlMaterialThemeProvider>
          <LoginPage setIsLoading={setIsLoading} />
        </StihlMaterialThemeProvider>
      </AppErrorBoundary>
    );
  }

  if (
    !session.hasAcceptedTermsAndConditions &&
    (session.license === AccessLicenses.DealerLicense ||
      session.license === AccessLicenses.DealerUsLicense)
  ) {
    return (
      <AppErrorBoundary>
        <StihlMaterialThemeProvider>
          <TermsAndConditionsModal />
        </StihlMaterialThemeProvider>
      </AppErrorBoundary>
    );
  }

  return (
    <AppErrorBoundary>
      <StihlMaterialThemeProvider>
        {isPageLoadFinished && !isLoading ? (
          <>
            <Router>
              <AppMain dealerDetails={dealerDetails}>
                <Switch>
                  {session.navigationItems.includes(NavigationItem.Device) && (
                    <Route path="/device">
                      <DeviceDetails />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.SystemHealthStatus,
                  ) && (
                    <Route path="/system-health">
                      <div>
                        <SystemHealthOverview />
                      </div>
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.DeviceManagement,
                  ) && (
                    <Route path="/device-management">
                      <DeviceManagementTabsComponent />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.CustomerManagment,
                  ) && (
                    <Route path="/customer-management/register">
                      <CustomerAccountRegistrationForm />
                    </Route>
                  )}
                  {session.navigationItems.includes(
                    NavigationItem.CustomerManagment,
                  ) && (
                    <Route path="/customer-management">
                      <CustomerManagementTabsComponent />
                    </Route>
                  )}
                  {session.navigationItems.includes(NavigationItem.Help) && (
                    <Route path="/help">
                      <HelpTabsComponent dealerLocale={dealerDetails.locale} />
                    </Route>
                  )}
                  <Route path={loginPath}>
                    <Redirect to="/device" />
                  </Route>
                  <Route path="*">
                    <Redirect to="/device" />
                  </Route>
                </Switch>
              </AppMain>
            </Router>
            <StihlSnackbarAlert alert={alert} setAlert={setAlert} />
          </>
        ) : (
          <PageWrapper>
            <CircularProgress size="5rem" data-testid="loadingScreen" />
          </PageWrapper>
        )}
      </StihlMaterialThemeProvider>
    </AppErrorBoundary>
  );
};
export default App;
/* eslint-enable react/jsx-max-depth */
/* eslint-enable max-lines-per-function */
