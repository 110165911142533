import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers';
import type { Dispatch, SetStateAction, FC } from 'react';
import { useState, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { StihlHelperText } from '../../../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import { SthilLocalizationProvider } from '../../../../../base/stihl-material-ui/components/stihl-localization-provider/stihl-localization-provider';
import StihlIconBin from '../../../../../base/stihl-material-ui/icons/stihl-icon-bin';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledContainer = styled.form`
  display: flex;
  align-items: flex-start;
  padding-block-start: 1.5rem;
  color: black;
`;

const RangeTypography = styled(Typography)`
  align-self: center;
  padding-inline: 0.5rem;
`;

const StyledClearChip = styled(Chip)`
  align-self: center;
  margin-inline: 1rem 0.75rem;
  padding-inline-start: 0.33rem;
  background-color: ${stihlColor.greyLight};
`;

export type ConnectionHistoryDatepickerProps = {
  dateStart: Date;
  setDateStart: Dispatch<SetStateAction<Date>>;
  dateEnd: Date;
  setDateEnd: Dispatch<SetStateAction<Date>>;
};

export const ConnectionHistoryDatepicker: FC<
  ConnectionHistoryDatepickerProps
> = ({ dateStart, setDateStart, dateEnd, setDateEnd }) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);

  function clearFilter(): void {
    setHasError(false);

    setDateStart(() => {
      const now = new Date(Date.now());
      now.setMonth(now.getMonth() - 1);
      now.setHours(0, 0, 0, 0);

      return now;
    });

    setDateEnd(() => {
      const now = new Date(Date.now());
      now.setHours(23, 59, 0, 0);

      return now;
    });
  }

  const labelId = useId();

  return (
    <>
      <StyledContainer>
        <SthilLocalizationProvider>
          <RangeTypography id={`${labelId}-from`}>
            {t('connectionHistory.startDate')}
          </RangeTypography>
          <DateTimePicker
            value={dateStart}
            onChange={(value) => {
              if (value != null) {
                setHasError(false);
                setDateStart(value);
              }
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
            slotProps={{
              textField: {
                inputProps: { 'aria-labelledby': `${labelId}-from` },
              },
            }}
          />
          <RangeTypography id={`${labelId}-to`}>
            {t('connectionHistory.endDate')}
          </RangeTypography>
          <DateTimePicker
            value={dateEnd}
            onChange={(value) => {
              if (value != null) {
                setHasError(false);
                setDateEnd(value);
              }
            }}
            onError={(error) => {
              setHasError(error != null); // Sometimes falsely triggered with error = null
            }}
            slotProps={{
              textField: {
                inputProps: { 'aria-labelledby': `${labelId}-to` },
              },
            }}
          />
        </SthilLocalizationProvider>
        <StyledClearChip
          label={t('connectionHistory.clearDateFilter')}
          icon={<StihlIconBin />}
          onClick={clearFilter}
        />
      </StyledContainer>
      {hasError && <StihlHelperText text="deviceManagementFilter.errorDate" />}
    </>
  );
};
