import styled from '@emotion/styled';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import QueryComponent from '../../../../app-shell/ui/query-component';
import type { ConnectionHistoryCard as ConnectionHistoryCardValue } from '../../../model/card.model';
import type {
  Connection,
  ConnectionGraphData,
} from '../../../model/connection.model';
import { ConnectionDataType } from '../../../model/connection.model';
import { useConnectionHistory } from '../../../service/device-service/device-service';
import CardField from '../../card-field/card-field';
import { CardBase, ContentWrapper } from '../generic-card/generic-card';
import ConnectionHistoryChart from './connection-history-chart';
import { ConnectionHistoryDatepicker } from './connection-history-datepicker';
import { ConnectionHistoryLineSelect } from './connection-history-line-select';
import ConnectionHistoryPieChart from './connection-history-pie-chart';

const StyledCardContent = styled(CardContent)`
  padding-inline: 0;
  padding-block-start: 0;
`;

type ConnectionHistoryCardProps = {
  cardData: ConnectionHistoryCardValue;
};

function useConnectionGraphData(
  connectionHistory: Connection[] | undefined,
): ConnectionGraphData[] | undefined {
  return useMemo(() => {
    return connectionHistory?.map((connection) => {
      return {
        [ConnectionDataType.CellularConnected]: connection.cellularIsConnected
          ? 1
          : 0,
        [ConnectionDataType.DeviceConnected]:
          connection.deviceIsCurrentlyConnected ? 1 : 0,
        [ConnectionDataType.WifiConnected]: connection.wifiIsConnected ? 1 : 0,
        timestamp: new Date(connection.lastUpdatedTimestamp).valueOf(),
        providerId: connection.cellularNetworkInformation_providerId ?? '',
      };
    });
  }, [connectionHistory]);
}

export const ConnectionHistoryCard: FC<ConnectionHistoryCardProps> = ({
  cardData,
}) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const [isConnectionHistoryRequested, setIsConnectionHistoryRequested] =
    useState(false);

  const [dateStart, setDateStart] = useState<Date>(() => {
    const now = new Date(Date.now());
    now.setMonth(now.getMonth() - 1);
    now.setHours(0, 0, 0, 0);

    return now;
  });

  const [dateEnd, setDateEnd] = useState<Date>(() => {
    const now = new Date(Date.now());
    now.setHours(23, 59, 0, 0);

    return now;
  });

  const [lineShown, setLineShown] = useState({
    [ConnectionDataType.WifiConnected]: true,
    [ConnectionDataType.DeviceConnected]: true,
    [ConnectionDataType.CellularConnected]: true,
  });

  const connectionHistory = useConnectionHistory({
    deviceId: cardData.deviceId,
    deviceModel: cardData.deviceModel,
    enabled: isConnectionHistoryRequested,
    from: dateStart,
    to: dateEnd,
  });
  const connectionData = useConnectionGraphData(connectionHistory.data);

  useEffect(() => {
    if (connectionHistory.isError) {
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('connectionHistory.getHistoryDataError'),
      });
    }
  }, [connectionHistory.isError, setAlert, t]);

  function fetchHistory(): void {
    setIsConnectionHistoryRequested(true);
  }

  const handleToggleLineShown = (
    connectionDataType: ConnectionDataType,
  ): void => {
    setLineShown((previousState) => {
      const newState = { ...previousState };
      newState[connectionDataType] = !previousState[connectionDataType];
      return newState;
    });
  };

  return (
    <CardBase title={cardData.title} data-testid="connectionHistoryCard">
      <StyledCardContent>
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>
            <CardField value={data} />
          </ContentWrapper>
        ))}
      </StyledCardContent>

      {isConnectionHistoryRequested ? (
        <ConnectionHistoryDatepicker
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
        />
      ) : (
        <StihlButtonPrimaryLoading onClick={fetchHistory}>
          {t('connectionHistory.getHistoryData')}
        </StihlButtonPrimaryLoading>
      )}

      <QueryComponent data={connectionHistory}>
        {connectionData && connectionData.length === 0 && (
          <Typography sx={{ marginTop: '1rem' }}>
            {t('connectionHistory.notHistory')}
          </Typography>
        )}

        {connectionData && connectionData.length > 0 && (
          <>
            <ConnectionHistoryLineSelect
              isWifiConnectedShown={lineShown[ConnectionDataType.WifiConnected]}
              isDeviceConnectedShown={
                lineShown[ConnectionDataType.DeviceConnected]
              }
              isCellularConnectedShown={
                lineShown[ConnectionDataType.CellularConnected]
              }
              onLineSelect={handleToggleLineShown}
            />

            <ConnectionHistoryChart
              connectionData={connectionData}
              isLineVisible={lineShown}
              startTimestamp={dateStart.getTime()}
              endTimestamp={dateEnd.getTime()}
            />

            <ConnectionHistoryPieChart
              connectionData={connectionHistory.data as Connection[]}
              isLineVisible={lineShown}
              startTimestamp={dateStart.getTime()}
              endTimestamp={dateEnd.getTime()}
            />
          </>
        )}
      </QueryComponent>
    </CardBase>
  );
};

export default ConnectionHistoryCard;
