import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { changeLanguage } from 'i18next';
import type { JSX } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconGlobe from '../../../base/stihl-material-ui/icons/stihl-icon-globe';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledButton = styled(Button)`
  padding: 0.25rem 1rem;
  block-size: 1.5rem;
  text-transform: none;
  background-color: ${stihlColor.greyDark};
  color: ${stihlColor.white};

  &:hover {
    background: ${stihlColor.fontGrey};
  }
`;

const StyledMenu = styled(Menu)`
  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiMenu-paper {
    inline-size: 180px;
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiList-root {
    padding-block: 0;
    padding-block-end: 0;
    border: 1px solid ${stihlColor.greyBase};
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiMenuItem-root {
    padding-block: 1rem;
    padding-block-end: 1rem;
    color: ${stihlColor.white};
    background-color: ${stihlColor.greyDark};

    &:hover,
    &:focus {
      background-color: ${stihlColor.fontGrey};
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  block-size: 2rem;
`;

export const LanguageMenu = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    return setAnchorElement(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorElement(null);
  }

  const selectedLanguage = useMemo(
    () =>
      new Intl.DisplayNames([i18n.language], {
        type: 'language',
      }),
    [i18n.language],
  );

  return (
    <div>
      <StyledButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <StihlIconGlobe />
        <Typography component="span" ml={1} mr={1}>
          {selectedLanguage.of(i18n.language.split('-')[0])}
        </Typography>
      </StyledButton>

      <StyledMenu
        variant="selectedMenu"
        disableAutoFocusItem
        elevation={0}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        id="language-menu"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LanguagePicker onClick={handleClose} />
      </StyledMenu>
    </div>
  );
};

const LanguagePicker = ({ onClick }: { onClick: () => void }): JSX.Element => {
  const availableLanguages = [
    'bg-BG',
    'hr-HR',
    'cs-CZ',
    'da-DK',
    'nl-NL',
    'en-US',
    'et-EE',
    'fi-FI',
    'fr-FR',
    'de-DE',
    'el-GR',
    'hu-HU',
    'it-IT',
    'lv-LV',
    'lt-LT',
    'nb-NO',
    'pl-PL',
    'pt-PT',
    'ro-RO',
    'sr-RS',
    'sk-SK',
    'sl-SI',
    'es-ES',
    'sv-SE',
  ];

  const languagesInEnglish = useMemo(
    () =>
      new Intl.DisplayNames(['en-US'], {
        type: 'language',
      }),
    [],
  );

  return (
    <>
      {availableLanguages.map((language) => (
        <StyledMenuItem
          key={language}
          onClick={() => {
            void changeLanguage(language);
            onClick();
          }}
        >
          {languagesInEnglish.of(language.split('-')[0])}
        </StyledMenuItem>
      ))}
    </>
  );
};
