import i18next from 'i18next';
import type { Dtc } from '../../model/dtc.model';

export function dtcMapper(): Dtc[] {
  const dtcArray = Object.entries(
    i18next.getResourceBundle(i18next.language, 'dtc') as Record<
      string,
      { headline: string; description: string; component?: string }
    >,
  );

  return dtcArray.map<Dtc>(([code, descriptor]) => ({
    code,
    ...descriptor,
  }));
}
