import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterParameterValue } from '../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type { ChipType } from '../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import QueryComponent from '../../app-shell/ui/query-component';
import type { DeviceModel, DeviceType, ItemType } from '../../device/model';
import { useManagedDevices } from '../service/device-management-service/device-management-service';
import { getDefaultProcessingChain } from '../service/processing-chain';
import DeviceManagementFilter from './overview-items/device-filters/device-management-filter';
import DeviceManagementTable from './overview-items/device-management-table/device-management-table';
import { typeModelMatcher } from './overview-items/utils/device-management.const';

const emptyArray: never[] = [];

const DeviceManagementOverview = ({
  isAllowedForUpdateManagement,
}: {
  isAllowedForUpdateManagement: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [store] = useStore();

  const initialDeviceModel =
    store.deviceModels.length === 1 ? store.deviceModels[0] : undefined;
  const [deviceModel, setDeviceModel] = useState<DeviceModel | undefined>(
    initialDeviceModel,
  );

  const initialDeviceTypes =
    initialDeviceModel == null ? [] : typeModelMatcher[initialDeviceModel];
  const [deviceTypes, setDeviceTypes] =
    useState<ItemType<DeviceType>[]>(initialDeviceTypes);

  const defaultProcessingChain = getDefaultProcessingChain();
  const [processingChain, setProcessingChain] = useState<string | undefined>(
    defaultProcessingChain,
  );

  const initialProcessingChainChip: ChipType = {
    filter: FilterParameterValue.ProcessingChain,
    searchTerm: defaultProcessingChain,
  };
  const initialChips = [initialProcessingChainChip];

  if (initialDeviceModel != null) {
    const initialDeviceModelChip: ChipType = {
      filter: FilterParameterValue.DeviceModel,
      searchTerm: initialDeviceModel,
    };

    initialChips.unshift(initialDeviceModelChip);
  }

  const [chips, setChips] = useState<ChipType[]>(initialChips);

  function handleDeviceModel(model: DeviceModel | undefined): void {
    setDeviceModel(model);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const newDeviceTypes = model ? typeModelMatcher[model] : emptyArray;
    setDeviceTypes(newDeviceTypes);
  }

  const deviceManagementDevices = useManagedDevices(chips);

  return (
    <>
      <DeviceManagementFilter
        chips={chips}
        setChips={setChips}
        deviceModelCallback={handleDeviceModel}
        processingChain={processingChain}
        setProcessingChain={setProcessingChain}
        deviceTypes={deviceTypes}
        deviceModel={deviceModel}
        handleSearchCallback={() => {
          void deviceManagementDevices.refetch();
        }}
        isSearchLoading={deviceManagementDevices.isFetching}
      />

      <QueryComponent
        data={deviceManagementDevices}
        placeholderText={t('deviceManagementFilter.hint')}
      >
        {deviceManagementDevices.data ?? deviceManagementDevices.error ? (
          <DeviceManagementTable
            deviceManagementDevices={deviceManagementDevices.data}
            deviceModel={deviceModel}
            error={deviceManagementDevices.error}
            chips={chips}
            isAllowedForUpdateManagement={isAllowedForUpdateManagement}
          />
        ) : undefined}
      </QueryComponent>
    </>
  );
};

export default DeviceManagementOverview;
