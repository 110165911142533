import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import type { UseQueryResult } from '@tanstack/react-query';
import type { JSX, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block-start: 5%;
  text-align: center;
`;

const QueryComponent = ({
  data,
  placeholderText,
  children,
}: {
  data: Partial<UseQueryResult<unknown, Error>>;
  placeholderText?: string;
  children: ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {!data.isLoading &&
        !data.isError &&
        data.data == null &&
        placeholderText && (
          <Wrapper>
            <Typography data-testid="searchPrompt">
              {placeholderText}
            </Typography>
          </Wrapper>
        )}
      {Boolean(data.isLoading) && (
        <Wrapper>
          <CircularProgress size="5rem" />
          <p>
            <Typography component="span" data-testid="queryLoadingInfo">
              {t('queryLoadingInfo')}
            </Typography>
          </p>
        </Wrapper>
      )}

      {children}
    </>
  );
};

export default QueryComponent;
