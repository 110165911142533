/* eslint-disable no-secrets/no-secrets */
export enum StihlDeviceEnvironment {
  Int = 'int',
  Uat = 'uat',
  Prd = 'prd',
}

export enum AccessLicenses {
  DealerLicense = 'Licence_iServicePortal_Dealer',
  DealerUsLicense = 'Licence_iServicePortal_DealerUS',
  VuLicense = 'Licence_iServicePortal_Subsidiary',
  VuUsLicense = 'Licence_iServicePortal_SubsidiaryUS',
  FirstLevelSupportLicense = 'Licence_iServicePortal_1st_Level',
  SecondLevelSupportLicense = 'Licence_iServicePortal_Headquarter',
  DeveloperLicense = 'Licence_iServicePortal_R&D_imow',
  ConnectedBoxSupportLicense = 'Licence_iServicePortal_R&D_box',
  ReleaseManagerLicense = 'Licence_iServicePortal_Releasemanager',
}

export type LogResponse = {
  title: string;
  downloadUrl: string;
};

export enum DeviceModel {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iMowIA01 = 'IA01',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iMowIA01Plus = 'IA01_drahtlos',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iMowIA02 = 'IA02',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  connectedBox = 'CE02',
  Legacy6301 = '6301',
  Legacy6303 = '6303',
  Legacy6309 = '6309',
}

export enum DeviceType {
  Imow3 = 'iMOW 3',
  Imow4 = 'iMOW 4',
  Imow5 = 'iMOW 5',
  Imow6 = 'iMOW 6',
  Imow7 = 'iMOW 7',
  Imow3Evo = 'iMOW 3 Evo',
  Imow4Evo = 'iMOW 4 Evo',
  Imow5Evo = 'iMOW 5 Evo',
  Imow6Evo = 'iMOW 6 Evo',
  Imow7Evo = 'iMOW 7 Evo',
  Imow5Evo4Inch = 'iMOW 5 Evo 4 Inch',
  Imow6Evo4Inch = 'iMOW 6 Evo 4 Inch',
  Imow7Evo4Inch = 'iMOW 7 Evo 4 Inch',
  Imow7Pro = 'iMOW 7 Pro',
  Imow7Pro4Inch = 'iMOW 7 Pro 4 Inch',
  Imow5PlusEvo = 'iMOW 5 Plus Evo',
  Imow6PlusEvo = 'iMOW 6 Plus Evo',
  Imow7PlusEvo = 'iMOW 7 Plus Evo',
  Rmi422 = 'RMI 422',
  Rmi422P = 'RMI 422 P',
  Rmi422Pc = 'RMI 422 PC',
  Rmi522C = 'RMI 522 C',
  Rmi632 = 'RMI 632',
  Rmi632Pc = 'RMI 632 PC',
  ConnectedBoxEuBasic = 'connected Box EU basic',
  ConnectedBoxEuMobile = 'connected Box EU mobile',
  ConnectedBoxUsBasic = 'connected Box US basic',
  ConnectedBoxUsMobile = 'connected Box US mobile',
}

export enum StihlDeviceTag {
  StandardCustomerDevice = 'standardCustomerDevice',
  SimulatedDevice = 'simulatedDevice',
  StihlInternalTestDevice = 'stihlInternalTestDevice',
  StihlDealerTestDevice = 'stihlDealerTestDevice',
  BetaCustomerDevice = 'betaCustomerDevice',
  FriendlyCustomerDevice = 'friendlyCustomerDevice',
  LeasingCustomerDevice = 'leasingCustomerDevice',
  VipCustomerDevice = 'vipCustomerDevice',
  StihlKpDevice = 'stihlKPDevice',
  StihlVpt1Device = 'stihlVPT1Device',
  StihlVpt2Device = 'stihlVPT2Device',
  StihlVpt3Device = 'stihlVPT3Device',
  StihlV1SeriesDevice = 'stihlV1SeriesDevice',
  StihlV2SeriesDevice = 'stihlV2SeriesDevice',
  StihlV3SeriesDevice = 'stihlV3SeriesDevice',
  StihlV4SeriesDevice = 'stihlV4SeriesDevice',
  StihlEseriesDevice = 'stihlESeriesDevice',
  StihlMarketingDevice = 'stihlMarketingDevice',
  StihlFieldTestDevice = 'stihlFieldTestDevice',
}

export enum DeviceConnectionState {
  Connected = 'connected',
  Disconnected = 'disconnected',
  NeverOnlineBefore = 'neverOnlineBefore',
}

export type ItemType<T> = {
  value: T;
  label: string;
};

export type DiagnosisSessionData = {
  id: string;
  timestamp: string;
  symptoms: string;
  ecus?: EcuData[];
};

export type EcuData = {
  id: string;
  errors: string[];
  data: EcuDetailsData[];
};

export type EcuDetailsData = {
  id: string;
  timeStamp: string;
  name: string;
  valueWithUnit: string;
};

export type CertificateStatus = {
  birthIssuer?: string;
  birthValidFrom?: number;
  birthValidTo?: number;
  lifecycleValidFrom?: number;
  lifecycleValidTo?: number;
  diagnosisValidFrom?: number;
  diagnosisValidTo?: number;
};

export type PermissionRequestResponse = {
  id: string;
  deviceId: string;
  state: 'pending' | 'denied' | 'granted' | 'timed_out';
  accessLevel: 'read-only' | 'read-write';
  requestedBy: {
    id: string;
    identityProvider: 'stihlid' | 'websso' | 'azuread';
  };
  expiresAt: string;
};
/* eslint-enable no-secrets/no-secrets */
