import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { QualityOfMobileConnectionCard as MobileConnectionQualityCardValue } from '../../../model/card.model';
import CardFieldContent from '../../card-field/card-field-content/card-field-content';
import { CardBase } from '../generic-card/generic-card';

const SignalStrengthContained = styled.span`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span<{
  color: string;
}>`
  color: ${(props) => props.color};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export type MobileConnectionQualityCardProps = {
  cardData: MobileConnectionQualityCardValue;
};

const MobileConnectionQualityCard: FC<MobileConnectionQualityCardProps> = ({
  cardData,
}) => {
  const { t } = useTranslation();
  const { toLocaleDateTimeString } = useDateFormatting();

  const { value: cellularNetworkInformation, lastUpdated } =
    cardData.cardFields[0].value;

  let currentSignalStrengthQuality = '';
  let currentSignalStrengthQualityColor = 'black';

  const isValidPercentage =
    cellularNetworkInformation.currentSignalStrength <= 100 &&
    cellularNetworkInformation.currentSignalStrength >= 0;
  if (isValidPercentage) {
    if (cellularNetworkInformation.currentSignalStrength >= 70) {
      currentSignalStrengthQuality = t('connections.excellent');
      currentSignalStrengthQualityColor = stihlColor.greenDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 45) {
      currentSignalStrengthQuality = t('connections.good');
      currentSignalStrengthQualityColor = stihlColor.greenDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 22) {
      currentSignalStrengthQuality = t('connections.okay');
      currentSignalStrengthQualityColor = stihlColor.yellowDark;
    } else if (cellularNetworkInformation.currentSignalStrength >= 5) {
      currentSignalStrengthQuality = t('connections.poor');
      currentSignalStrengthQualityColor = stihlColor.redDark;
    } else {
      currentSignalStrengthQuality = t('connections.noSignal');
      currentSignalStrengthQualityColor = stihlColor.redDark;
    }
  }

  return (
    <CardBase title={cardData.title} data-testid="mobileConnectionCard">
      <CardFieldContent name={t('connections.lastCellularType')}>
        {cellularNetworkInformation.connectionType}
      </CardFieldContent>
      <CardFieldContent name={t('connections.lastProvider')}>
        {cellularNetworkInformation.providerId}
      </CardFieldContent>
      <CardFieldContent name={t('connections.currentSignalStrength')}>
        <SignalStrengthContained>
          {`${cellularNetworkInformation.currentSignalStrength}${
            isValidPercentage ? '%' : ''
          }`}

          {isValidPercentage && (
            <>
              <StyledSpan color={currentSignalStrengthQualityColor}>
                {currentSignalStrengthQuality}
              </StyledSpan>
              <StihlTooltip
                title={
                  <>
                    <Typography>
                      {t('connections.signalStrengthTooltipExcellent')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipGood')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipOkay')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipPoor')}
                    </Typography>
                    <Typography>
                      {t('connections.signalStrengthTooltipNoSignal')}
                    </Typography>
                  </>
                }
                placement="bottom"
              >
                <StihlIconInfo data-testid="connectionQualityInfoIcon" />
              </StihlTooltip>
            </>
          )}
        </SignalStrengthContained>
      </CardFieldContent>
      <CardFieldContent name={t('connections.lastUpdated')}>
        {toLocaleDateTimeString(lastUpdated * 1000)}
      </CardFieldContent>
    </CardBase>
  );
};

export default MobileConnectionQualityCard;
