import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldPercentageProps = {
  name: string;
  value: number;
  icon?: ReactNode;
};

const CardFieldPercentage: FC<CardFieldPercentageProps> = ({
  name,
  icon,
  value,
}) => {
  const { i18n } = useTranslation();
  const numberFormat = useMemo(
    () => new Intl.NumberFormat(i18n.language, { style: 'percent' }),
    [i18n.language],
  );

  return (
    <CardFieldContent name={name} icon={icon}>
      <Typography>{numberFormat.format(value)}</Typography>
    </CardFieldContent>
  );
};

export default CardFieldPercentage;
