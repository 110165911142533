import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconLegend from '../../../../../base/stihl-material-ui/icons/stihl-icon-legend';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { ConnectionDataType } from '../../../model/connection.model';
import {
  cellularConnectedColor,
  deviceConnectedColor,
  wifiConnectedColor,
} from './connection-history-tooltip';

type ConnectionHistoryPieChartLegendProps = {
  isLineVisible: Record<ConnectionDataType, boolean>;
  className?: string;
};

const LegendContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ConnectionHistoryPieChartLegend: FC<
  ConnectionHistoryPieChartLegendProps
> = ({ isLineVisible, className }) => {
  const { t } = useTranslation();

  return (
    <LegendContainer className={className}>
      {isLineVisible[ConnectionDataType.WifiConnected] && (
        <Typography component="li">
          <StihlIconLegend color={wifiConnectedColor} />{' '}
          {t('connectionHistory.wifiConnected')}
        </Typography>
      )}
      {isLineVisible[ConnectionDataType.DeviceConnected] && (
        <Typography component="li">
          <StihlIconLegend color={deviceConnectedColor} />{' '}
          {t('connectionHistory.deviceConnected')}
        </Typography>
      )}
      {isLineVisible[ConnectionDataType.CellularConnected] && (
        <Typography component="li">
          <StihlIconLegend color={cellularConnectedColor} />{' '}
          {t('connectionHistory.mobileConnection')}
        </Typography>
      )}
      <Typography component="li">
        <StihlIconLegend color={stihlColor.greyBase} />{' '}
        {t('connectionHistory.notConnected')}
      </Typography>
      <Typography component="li">
        <StihlIconLegend color={stihlColor.greyLight} />{' '}
        {t('connectionHistory.connectionUnknown')}
      </Typography>
    </LegendContainer>
  );
};

export default ConnectionHistoryPieChartLegend;
