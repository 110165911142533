/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlButtonSecondary from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlIconBoxLink from '../../../../base/stihl-material-ui/icons/stihl-icon-box-link';
import StihlIconClose from '../../../../base/stihl-material-ui/icons/stihl-icon-close';
import StihlIconMowerLink from '../../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import QueryComponent from '../../../app-shell/ui/query-component';
import { DeviceModel } from '../../../device/model';
import { SearchParameterValues } from '../../../device/model/search.model';
import { DeviceTag } from '../../../device/ui/card/flags-card/flags-card';
import { useManagedDeviceDetails } from '../../service/device-management-service/device-management-service';
import ConnectionState from '../common/connection-state';
import {
  DeviceManagementTableContext,
  DeviceStateActionType,
  // eslint-disable-next-line import/max-dependencies
} from './device-management-table/device-management-table.state';

const StyledCard = styled(Card)`
  min-inline-size: 350px;
  max-inline-size: 400px;

  @media (max-width: 1024px) {
    max-inline-size: 350px;
  }
`;

const StyledCardHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 60px;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

const CloseButtonPositioner = styled.div`
  position: absolute;
  inset-block-start: 0.5rem;
  inset-inline-end: 1rem;
`;

const StyledFieldTitle = styled(Typography)`
  color: ${stihlColor.fontGrey};
`;

const StyledGridColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
const StyledFieldGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  overflow-wrap: break-word;
`;

const StyledButtonContainer = styled(Grid)`
  display: flex;
  place-content: end center;
  inline-size: 100%;
`;

const StyledButtonContent = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export type ManagedDeviceDetailsProps = {
  deviceModel: DeviceModel | undefined;
  softwareVersion: string;
  isConnected: boolean;
};

// eslint-disable-next-line max-lines-per-function
const ManagedDeviceDetails = ({
  deviceModel,
  softwareVersion,
  isConnected,
}: ManagedDeviceDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoadingDevice, setIsLoadingDevice] = useState<boolean>(false);

  const history = useHistory();

  const deviceManagementTableContext = useContext(DeviceManagementTableContext);
  const { state, dispatch } = deviceManagementTableContext;

  const deviceDetailsQueryResult = useManagedDeviceDetails(
    state.currentOpenDetailsId,
    deviceModel,
  );

  const [session] = useStore();

  const deviceDetails = deviceDetailsQueryResult.data;
  if (deviceDetails) {
    deviceDetails.softwareVersion = softwareVersion;
    deviceDetails.isConnected = isConnected;
  }

  useEffect(() => {
    if (deviceDetailsQueryResult.isError) {
      dispatch({ type: DeviceStateActionType.DetailsOpen, value: false });
      dispatch({ type: DeviceStateActionType.CurrentOpenDetailsId, value: '' });
    }
  }, [deviceDetailsQueryResult.isError, dispatch]);

  function handleOpenDevice(deviceDetailsDeviceModel: DeviceModel): void {
    setIsLoadingDevice(true);

    history.push({
      pathname: '/device',
      state: {
        search: state.currentOpenDetailsId,
        selector: SearchParameterValues.DeviceId,
        deviceModel: deviceDetailsDeviceModel,
      },
    });
  }

  function handleCloseDetails(): void {
    dispatch({ type: DeviceStateActionType.DetailsOpen, value: false });
    dispatch({ type: DeviceStateActionType.CurrentOpenDetailsId, value: '' });
  }

  function returnLoadingOrLogo(): JSX.Element {
    return isLoadingDevice ? (
      <CircularProgress />
    ) : (
      <StyledButtonContent>
        {t('deviceManagementTable.goToDevice')}
        {returnBoxOrImowLogo()}
      </StyledButtonContent>
    );
  }

  function returnBoxOrImowLogo(): JSX.Element {
    return deviceDetails?.deviceModel === DeviceModel.connectedBox ? (
      <StihlIconBoxLink color="white" />
    ) : (
      <StihlIconMowerLink />
    );
  }

  return (
    <StyledCard data-testid="deviceManagementDetailsCard">
      <QueryComponent data={deviceDetailsQueryResult}>
        {deviceDetails && (
          <div>
            <StyledCardHeader>
              <Typography variant="h3">{deviceDetails.serialNumber}</Typography>
              <CloseButtonPositioner>
                <IconButton
                  data-testid="detailsCloseButton"
                  onClick={handleCloseDetails}
                >
                  <StihlIconClose />
                </IconButton>
              </CloseButtonPositioner>
            </StyledCardHeader>
            <CardContent data-testid="deviceManagementDetailsContent">
              <Grid container spacing={2}>
                <StyledGridColumn item xs={6}>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.deviceId')}
                    </StyledFieldTitle>
                    <Typography>{deviceDetails.deviceId}</Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.deviceType')}
                    </StyledFieldTitle>
                    <Typography>
                      {t(deviceDetails.deviceType ?? 'unknown')}
                    </Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.productionDate')}
                    </StyledFieldTitle>
                    <Typography>
                      {deviceDetails.productionDate
                        ? new Date(
                            deviceDetails.productionDate,
                          ).toLocaleDateString()
                        : t('unknown')}
                    </Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.connectionState')}
                    </StyledFieldTitle>
                    <ConnectionState
                      isConnected={deviceDetails.isConnected ?? false}
                    />
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.processingChain')}
                    </StyledFieldTitle>
                    <Typography>{deviceDetails.processingChain}</Typography>
                  </StyledFieldGrid>
                </StyledGridColumn>
                <StyledGridColumn item xs={6}>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.deviceModel')}
                    </StyledFieldTitle>
                    <Typography>{deviceDetails.deviceModel}</Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.imei')}
                    </StyledFieldTitle>
                    <Typography>
                      {deviceDetails.imei ?? t('unknown')}
                    </Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.imsi')}
                    </StyledFieldTitle>
                    <Typography>
                      {deviceDetails.imsi ?? t('unknown')}
                    </Typography>
                  </StyledFieldGrid>
                  <StyledFieldGrid item>
                    <StyledFieldTitle>
                      {t('deviceManagementTable.softwareVersion')}
                    </StyledFieldTitle>
                    <Typography>
                      {deviceDetails.softwareVersion ?? t('unknown')}
                    </Typography>
                  </StyledFieldGrid>
                </StyledGridColumn>
                <StyledFieldGrid item>
                  <StyledFieldTitle>
                    {t('deviceManagementTable.tag')}
                  </StyledFieldTitle>
                  <DeviceTag
                    flags={session.deviceFlags}
                    deviceId={deviceDetails.deviceId}
                    fetchedTag={deviceDetails.tag ?? ''}
                  />
                </StyledFieldGrid>
                <StyledButtonContainer item>
                  <StihlButtonSecondary
                    data-testid="goToDeviceButton"
                    onClick={() => handleOpenDevice(deviceDetails.deviceModel)}
                  >
                    {returnLoadingOrLogo()}
                  </StihlButtonSecondary>
                </StyledButtonContainer>
              </Grid>
            </CardContent>
          </div>
        )}
      </QueryComponent>
    </StyledCard>
  );
};

export default ManagedDeviceDetails;

/* eslint-enable react/jsx-max-depth */
