import styled from '@emotion/styled';
import type { FC } from 'react';
import { useEffect, useRef, useState, useId, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { base64StringToUint8Array } from '../../../../../base/extras-javascript/base64';
import type { Point } from '../../../../../base/extras-javascript/geometry/point';
import { calculateBounds } from '../../../../../base/extras-javascript/geometry/point';
import type { MatrixTransform } from '../../../../../base/extras-javascript/geometry/point-transform';
import { transformPolygon } from '../../../../../base/extras-javascript/geometry/polygon';
import { useStihlTheme } from '../../../../../base/stihl-material-ui/theme/stihl-theme-provider';
import CardFieldGnssWorkingAreaMapScale from './card-field-gnss-working-area-map-scale';
import {
  Polygon_PolygonType,
  WorkingAreaCollection,
} from './working-area-collection';

type CardFieldGnssWorkingAreaMapProps = {
  name: string;
  value: string | null;
};

function generatePath(polygonPoints: Point[]): string {
  const [start = { x: 0, y: 0 }, ...rest] = polygonPoints;

  return `M${start.x},${start.y} ${rest
    .map((point) => `L${point.x},${point.y}`)
    .join(' ')} Z`;
}

function transformWorkingArea(
  workingArea: WorkingAreaCollection,
  transform: MatrixTransform,
): WorkingAreaCollection {
  return {
    ...workingArea,
    geometries: workingArea.geometries.map((geometry) => ({
      ...geometry,
      polygons: geometry.polygons.map((polygon) =>
        transformPolygon(polygon, transform),
      ),
    })),
  };
}

const MapLayout = styled.div`
  display: flex;
  justify-content: center;
`;

const SvgStyled = styled.svg`
  max-width: 1000px;
  max-height: 1000px;
`;

const CardFieldGnssWorkingAreaMapVisualization: FC<{
  value: string;
  name: string;
}> = ({ value, name }) => {
  const workingAreaCollection = useMemo(
    () =>
      transformWorkingArea(
        WorkingAreaCollection.decode(base64StringToUint8Array(value)),
        { a: 1, d: -1 },
      ),
    [value],
  );

  const bounds = useMemo(
    () =>
      calculateBounds(
        workingAreaCollection.geometries.flatMap((geometry) =>
          geometry.polygons.flatMap((polygon) => polygon.points),
        ),
      ),
    [workingAreaCollection],
  );

  const isWorkingAreaEmpty = useMemo(
    () =>
      workingAreaCollection.geometries.flatMap((geometry) =>
        geometry.polygons.flatMap((polygon) => polygon.points),
      ).length === 0,
    [workingAreaCollection],
  );

  const maskId = useId();
  const theme = useStihlTheme();
  const { t } = useTranslation();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [measuredHeight, setMeasuredHeight] = useState(0);

  useEffect(() => {
    if (!svgRef.current) return;

    const observer = new ResizeObserver((entries) => {
      const entry = entries.at(-1);

      setMeasuredHeight(Math.round(entry?.contentRect.height ?? 0));
    });

    observer.observe(svgRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const scaleFactor = measuredHeight === 0 ? 1 : bounds.yRange / measuredHeight;
  const labelId = useId();

  if (isWorkingAreaEmpty) {
    return <>{t('workingArea.gnssWorkingArea.mapEmpty')}</>;
  }

  return (
    <MapLayout>
      <SvgStyled
        viewBox={`${bounds.xMin} ${bounds.yMin} ${bounds.xRange} ${bounds.yRange + 30 * scaleFactor}`}
        ref={svgRef}
        role="graphics-document"
        aria-labelledby={labelId}
      >
        <title
          // eslint-disable-next-line react/forbid-dom-props
          id={labelId}
        >
          {t(name)}
        </title>

        <mask
          // eslint-disable-next-line react/forbid-dom-props
          id={maskId}
        >
          {workingAreaCollection.geometries.map((geometry) =>
            geometry.polygons.map((polygon) => (
              <path
                key={`${geometry.workingAreaId}-${polygon.polygonId}`}
                d={generatePath(polygon.points)}
                fill={
                  polygon.type === Polygon_PolygonType.INCLUSIVE
                    ? 'white'
                    : 'black'
                }
              />
            )),
          )}
        </mask>

        <rect
          x={bounds.xMin}
          y={bounds.yMin}
          width={bounds.xRange}
          height={bounds.yRange}
          fill={`${theme.palette.success.light}99`}
          mask={`url(#${maskId})`}
        />

        {workingAreaCollection.geometries.map((geometry) =>
          geometry.polygons.map((polygon) => (
            <path
              key={`${geometry.workingAreaId}-${polygon.polygonId}`}
              d={generatePath(polygon.points)}
              stroke={theme.palette.grey['400']}
              strokeWidth="1px"
              vectorEffect="non-scaling-stroke"
              fill="none"
            />
          )),
        )}

        <CardFieldGnssWorkingAreaMapScale
          x={bounds.xMin + 5 * scaleFactor}
          y={bounds.yRange + bounds.yMin}
          scaleFactor={scaleFactor}
          size={5}
        />
      </SvgStyled>
    </MapLayout>
  );
};

const CardFieldGnssWorkingAreaMap: FC<CardFieldGnssWorkingAreaMapProps> = ({
  value,
  name,
}) => {
  const { t } = useTranslation();

  if (value === null) {
    return <>{t('workingArea.gnssWorkingArea.noMap')}</>;
  }

  return (
    <ErrorBoundary fallback={t('workingArea.gnssWorkingArea.mapNotReadable')}>
      <CardFieldGnssWorkingAreaMapVisualization value={value} name={name} />
    </ErrorBoundary>
  );
};

export default CardFieldGnssWorkingAreaMap;
