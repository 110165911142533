import styled from '@emotion/styled';
import type { TypographyProps } from '@mui/material/Typography';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-link';
import StihlIconMedal from '../../../../../base/stihl-material-ui/icons/stihl-icon-medal';
import type { CardFieldCustomerValidValue } from '../../../model/card-field.model';
import CustomerDetailsActionsMenu from './cusomer-details/customer-details-actions-menu';
import CustomerDetailsRow from './cusomer-details/customer-details-row';

const ContentWrapper = styled.div`
  margin-block-end: 1rem;
`;

const StyledCustomerRole = styled((props: TypographyProps<'h4'>) => (
  <Typography {...props} component="h4" />
))`
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
`;

function handlePasswordReset(email: string): void {
  const encodedEmail = btoa(email);
  window.open(
    `${process.env.REACT_APP_STIHL_CONNECTED_BASE_URL}/account/forgot-password?email=${encodedEmail}`,
  );
}

type CardFieldCustomerDetailsCustomerProps = {
  value: CardFieldCustomerValidValue;
};

const CardFieldCustomerDetailsCustomer: FC<
  CardFieldCustomerDetailsCustomerProps
> = ({ value }) => {
  const { t } = useTranslation();
  const { customer, hasCustomerAccess, hasMatchingPreferredDealer } = value;

  return (
    <>
      <StyledCustomerRole>{t('customerDetails.owner')}</StyledCustomerRole>

      <ContentWrapper>
        <CustomerDetailsRow
          name={t('customerDetails.name')}
          hasMatchingPreferredDealer={hasMatchingPreferredDealer}
          leftIcon={<StihlIconMedal color="greyBase" />}
          value={`${customer.ownerFirstName} ${customer.ownerLastName}`}
          isVerified={customer.isAccountValid}
          hasCustomerAccess={hasCustomerAccess}
          rightIcon={
            <CustomerDetailsActionsMenu
              handlePasswordReset={() =>
                handlePasswordReset(customer.ownerEmail)
              }
              email={customer.ownerEmail}
              isDeputy={false}
              customerId={customer.customerId}
              subscriptionId=""
              name={`${customer.ownerFirstName} ${customer.ownerLastName}`}
              isVerified={Boolean(customer.isAccountValid)}
              isUserDealer={hasMatchingPreferredDealer}
              label={t('customerDetails.changeCustomerSettings')}
            />
          }
          shouldDisplayVerified
        />
        <CustomerDetailsRow
          name={t('customerDetails.email')}
          value={customer.ownerEmail}
          shouldDisplayVerified={false}
        />
        <CustomerDetailsRow
          name={t('customerDetails.salutation')}
          value={customer.ownerSalutation}
          shouldDisplayVerified={false}
        />
      </ContentWrapper>

      {customer.deputies.length > 0 && (
        <StyledCustomerRole>{t('customerDetails.deputies')}</StyledCustomerRole>
      )}
      {customer.deputies.map((deputy) => {
        const deputyName = `${deputy.deputyFirstName} ${deputy.deputyLastName}`;

        return (
          <ContentWrapper key={deputy.deputyEmail}>
            <CustomerDetailsRow
              name={t('customerDetails.name')}
              leftIcon={<StihlIconLink color="greyBase" />}
              hasMatchingPreferredDealer={
                deputy.hasDeputyMatchingPreferredDealer
              }
              value={deputyName}
              isVerified={deputy.isDeputyAccountValid}
              hasCustomerAccess={hasCustomerAccess}
              rightIcon={
                <CustomerDetailsActionsMenu
                  handlePasswordReset={() =>
                    handlePasswordReset(deputy.deputyEmail)
                  }
                  email={deputy.deputyEmail}
                  isDeputy
                  customerId={deputy.deputyId}
                  subscriptionId={deputy.subscriptionId}
                  name={deputyName}
                  isVerified={deputy.isDeputyAccountValid}
                  isUserDealer={hasMatchingPreferredDealer}
                  label={t('customerDetails.changeDeputySettings', {
                    deputyName,
                  })}
                />
              }
              shouldDisplayVerified
            />
            <CustomerDetailsRow
              name={t('customerDetails.email')}
              value={deputy.deputyEmail}
              shouldDisplayVerified={false}
            />
            <CustomerDetailsRow
              name={t('customerDetails.salutation')}
              value={deputy.deputySalutation}
              shouldDisplayVerified={false}
            />
          </ContentWrapper>
        );
      })}
    </>
  );
};

export default CardFieldCustomerDetailsCustomer;
