import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconValidFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { IconTextWrapper } from '../../../../customer-management/ui/customer-registration/customer-account-registration';

type CardFieldSubtitleProps = {
  name: string;
  value?: boolean;
};

const Validity: FC<{ value: boolean }> = ({
  value,
}: {
  value: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return value ? (
    <IconTextWrapper>
      <StihlIconValidFilled color="success" />
      <Typography color="text.success">{t('deviceDetails.isValid')}</Typography>
    </IconTextWrapper>
  ) : (
    <IconTextWrapper>
      <StihlIconXFilled color="error" />
      <Typography color="error">{t('deviceDetails.isInvalid')}</Typography>
    </IconTextWrapper>
  );
};

const CardFieldSubtitle: FC<CardFieldSubtitleProps> = ({ value, name }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ maxWidth: '850px' }}
    >
      <Grid item xs={value === undefined ? 12 : 4}>
        <Typography style={{ textTransform: 'uppercase' }}>
          {t(name)}
        </Typography>
      </Grid>

      {value !== undefined && <Validity value={value} />}
    </Grid>
  );
};

export default CardFieldSubtitle;
