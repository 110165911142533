import styled from '@emotion/styled';
import type { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

const ErrorText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 100vh;
  font-family: 'STIHL Contraface Display Title', sans-serif;
`;

const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary fallback={<ErrorText>{t('anyError')}</ErrorText>}>
      {children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundary;
