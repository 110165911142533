import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StihlFilterChips from '../../../../../../base/stihl-material-ui/components/stihl-filter-chips/stihl-filter-chips';
import EventLogFilterEventType from './event-log-filter-event-type';
import EventLogFilterTimeSpan, {
  timeSpanLabels,
} from './event-log-filter-time-span';

const EventLogFilterSelectLayout = styled.div`
  display: flex;
  gap: 1rem;
`;

type EventLogFilterProps = {
  hourTimeSpan: number | undefined;
  setHourTimeSpan: (hourTimeSpan: number | undefined) => void;
  eventTypesAvailable: string[];
  eventTypes: string[];
  setEventTypes: (eventTypes: string[]) => void;
};

const EventLogFilter: FC<EventLogFilterProps> = ({
  hourTimeSpan,
  setHourTimeSpan,
  eventTypesAvailable,
  eventTypes,
  setEventTypes,
}) => {
  const { t } = useTranslation();

  const chips = useMemo(() => {
    const filterChips: { id: string; displayValue: ReactNode }[] = [];

    if (hourTimeSpan != null) {
      filterChips.push({
        id: 'timespan',
        displayValue: t('eventLog.filter.timeSpan.chipValue', {
          timespanText: t(
            timeSpanLabels.find(
              (timeSpanLabel) => timeSpanLabel.value === hourTimeSpan,
            )?.labelKey ?? '',
          ),
        }),
      });
    }

    filterChips.push(
      ...eventTypes.map((eventType) => ({
        id: `eventType:${eventType}`,
        displayValue: t('eventLog.filter.eventType.chipValue', { eventType }),
      })),
    );

    return filterChips;
  }, [hourTimeSpan, eventTypes, t]);

  const setFilterChips = useCallback(
    (filterChips: { id: string }[]) => {
      const chipIds = new Set(filterChips.map(({ id }) => id));

      if (!chipIds.has('timespan')) {
        setHourTimeSpan(undefined);
      }

      setEventTypes(
        eventTypes.filter((eventType) => chipIds.has(`eventType:${eventType}`)),
      );
    },
    [setHourTimeSpan, eventTypes, setEventTypes],
  );

  return (
    <>
      <EventLogFilterSelectLayout>
        <div>
          <EventLogFilterTimeSpan
            hourTimeSpan={hourTimeSpan}
            setHourTimeSpan={setHourTimeSpan}
          />
        </div>
        <div>
          <EventLogFilterEventType
            eventTypesAvailable={eventTypesAvailable}
            eventTypes={eventTypes}
            setEventTypes={setEventTypes}
          />
        </div>
      </EventLogFilterSelectLayout>
      <StihlFilterChips
        chips={chips}
        setChips={setFilterChips}
        clearAllLabel={t('filterLabel.clearAllFilters')}
      />
    </>
  );
};

export default EventLogFilter;
