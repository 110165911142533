import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconEthernetConnected from '../../../../../base/stihl-material-ui/icons/stihl-icon-ethernet-connected';
import StihlIconEthernetDisconnected from '../../../../../base/stihl-material-ui/icons/stihl-icon-ethernet-disconnected';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldEthernetConnectionProps = {
  value: boolean;
  name: string;
};

const CardFieldEthernetConnection: FC<CardFieldEthernetConnectionProps> = ({
  value,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <CardFieldContent name={name}>
      <StihlIconTextFlexbox>
        {value ? (
          <StihlIconEthernetConnected />
        ) : (
          <StihlIconEthernetDisconnected />
        )}
        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('deviceDetails.isConnected')
            : t('deviceDetails.isDisconnected')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardFieldContent>
  );
};

export default CardFieldEthernetConnection;
