import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import type {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {
  dateTimeFormattingParamsDefault,
  useDateFormatting,
} from '../../../../../base/date-formatting/date-formatting';
import type { ConnectionGraphData } from '../../../model/connection.model';
import { ConnectionDataType } from '../../../model/connection.model';
import {
  cellularConnectedColor,
  ConnectionHistoryTooltip,
  deviceConnectedColor,
  wifiConnectedColor,
} from './connection-history-tooltip';

const ConnectionHistoryChartHeading = styled(Typography)`
  margin-block: 2.5rem 1rem;
`;

type ConnectionHistoryChartProps = {
  connectionData: ConnectionGraphData[];
  isLineVisible: Record<ConnectionDataType, boolean>;
  startTimestamp: number;
  endTimestamp: number;
};

function calculateXaxisTicks(dateRange: readonly [number, number]): number[] {
  const timeRange = dateRange[1] - dateRange[0];
  const ticks = [dateRange[0]];

  // more than one week -> one tick per day
  if (timeRange > 7 * 24 * 60 * 60 * 1000) {
    const day = new Date(dateRange[0]);
    day.setDate(day.getDate() + 1);
    day.setHours(0, 0, 0, 0);

    do {
      ticks.push(day.getTime());

      day.setDate(day.getDate() + 1);
    } while (day.getTime() < dateRange[1]);
  }
  // one week or less -> one tick per hour
  else {
    const hour = new Date(dateRange[0]);
    hour.setHours(hour.getHours() + 1, 0, 0, 0);

    do {
      ticks.push(hour.getTime());

      hour.setHours(hour.getHours() + 1);
    } while (hour.getTime() < dateRange[1]);
  }

  ticks.push(dateRange[1]);

  return ticks;
}

// eslint-disable-next-line max-lines-per-function
const ConnectionHistoryChart: FC<ConnectionHistoryChartProps> = ({
  connectionData,
  isLineVisible,
  startTimestamp,
  endTimestamp,
}) => {
  const { t } = useTranslation();
  const [opacity, setOpacity] = useState<Record<ConnectionDataType, number>>({
    [ConnectionDataType.WifiConnected]: 1,
    [ConnectionDataType.DeviceConnected]: 1,
    [ConnectionDataType.CellularConnected]: 1,
  });
  const { toLocaleDateString } = useDateFormatting();

  const customTooltip = useCallback(
    (props: TooltipProps<ValueType, NameType>) => {
      return (
        <ConnectionHistoryTooltip
          dataPointTimestamp={Number(props.label)}
          connectionData={connectionData}
        />
      );
    },
    [connectionData],
  );

  const xAxisTicks = useMemo(
    () => calculateXaxisTicks([startTimestamp, endTimestamp]),
    [startTimestamp, endTimestamp],
  );

  const formatAxisTick = useCallback(
    (tick: number): string => {
      const tickDate = new Date(tick);

      return toLocaleDateString(tickDate);
    },
    [toLocaleDateString],
  );

  const connectionDataCropped = useMemo(() => {
    if (connectionData.length === 0) return connectionData;

    const dataCropped = [...connectionData];

    if (dataCropped[0].timestamp < startTimestamp) {
      dataCropped[0] = {
        ...dataCropped[0],
        timestamp: startTimestamp,
      };
    }

    if (dataCropped[dataCropped.length - 1].timestamp >= endTimestamp) {
      dataCropped[dataCropped.length - 1] = {
        ...dataCropped[dataCropped.length - 1],
        timestamp: endTimestamp,
      };
    } else {
      dataCropped.push({
        ...dataCropped[dataCropped.length - 1],
        timestamp: endTimestamp,
      });
    }

    return dataCropped;
  }, [connectionData, startTimestamp, endTimestamp]);

  return (
    <>
      <ConnectionHistoryChartHeading variant="h3">
        {t('connectionHistory.lineChartHeading')}
      </ConnectionHistoryChartHeading>
      <ResponsiveContainer width="99%" height={320}>
        <LineChart
          data={connectionDataCropped}
          margin={{
            top: 32,
            right: 10,
            left: 60,
            bottom: 4,
          }}
          role="graphics-document"
          title={t('connectionHistory.lineChartAltText', {
            start: startTimestamp,
            end: endTimestamp,
            formatParams: {
              start: dateTimeFormattingParamsDefault,
              end: dateTimeFormattingParamsDefault,
            },
          })}
        >
          <XAxis
            dataKey="timestamp"
            domain={['dataMin', 'dataMax']}
            scale="time"
            type="number"
            tickFormatter={formatAxisTick}
            ticks={xAxisTicks}
            minTickGap={20}
            allowDataOverflow={false}
          />
          <YAxis
            yAxisId="left"
            label={{
              value: t('connectionHistory.connectionState'),
              position: 'insideLeft',
              dy: -132,
            }}
            type="number"
            domain={[0, 1]}
            ticks={[0, 1]}
            tickFormatter={(value: number) =>
              value
                ? t('connectionHistory.connected')
                : t('connectionHistory.notConnected')
            }
            orientation="left"
            padding={{ top: 40, bottom: 40 }}
          />
          {isLineVisible[ConnectionDataType.WifiConnected] && (
            <Line
              name={t('connectionHistory.wifiConnected')}
              yAxisId="left"
              type="stepAfter"
              dataKey={ConnectionDataType.WifiConnected}
              stroke={wifiConnectedColor}
              strokeOpacity={opacity[ConnectionDataType.WifiConnected]}
            />
          )}
          {isLineVisible[ConnectionDataType.DeviceConnected] && (
            <Line
              name={t('connectionHistory.deviceConnected')}
              yAxisId="left"
              type="stepAfter"
              dataKey={ConnectionDataType.DeviceConnected}
              stroke={deviceConnectedColor}
              strokeOpacity={opacity[ConnectionDataType.DeviceConnected]}
            />
          )}
          {isLineVisible[ConnectionDataType.CellularConnected] && (
            <Line
              name={t('connectionHistory.mobileConnection')}
              yAxisId="left"
              type="stepAfter"
              dataKey={ConnectionDataType.CellularConnected}
              stroke={cellularConnectedColor}
              strokeOpacity={opacity[ConnectionDataType.CellularConnected]}
            />
          )}
          <Legend
            onMouseEnter={(data) => {
              const { dataKey } = data as { dataKey: ConnectionDataType };
              setOpacity(() => {
                const newState = {
                  [ConnectionDataType.CellularConnected]: 0.3,
                  [ConnectionDataType.DeviceConnected]: 0.3,
                  [ConnectionDataType.WifiConnected]: 0.3,
                };
                newState[dataKey] = 1;
                return newState;
              });
            }}
            onMouseLeave={() => {
              setOpacity({
                [ConnectionDataType.CellularConnected]: 1,
                [ConnectionDataType.DeviceConnected]: 1,
                [ConnectionDataType.WifiConnected]: 1,
              });
            }}
          />
          <Tooltip content={customTooltip} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ConnectionHistoryChart;
