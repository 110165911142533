export type Point = {
  x: number;
  y: number;
};

export type Bounds = {
  xMin: number;
  yMin: number;
  xRange: number;
  yRange: number;
};

export function calculateBounds(points: Iterable<Point>): Bounds {
  const pointIterator = points[Symbol.iterator]();
  const firstEntry = pointIterator.next();

  if (firstEntry.done) return { xMin: 0, yMin: 0, xRange: 0, yRange: 0 };

  let xMin = firstEntry.value.x,
    xMax = firstEntry.value.x,
    yMin = firstEntry.value.y,
    yMax = firstEntry.value.y;

  for (
    let point = pointIterator.next();
    !point.done;
    point = pointIterator.next()
  ) {
    xMin = Math.min(xMin, point.value.x);
    xMax = Math.max(xMax, point.value.x);
    yMin = Math.min(yMin, point.value.y);
    yMax = Math.max(yMax, point.value.y);
  }

  return {
    xMin,
    yMin,
    xRange: xMax - xMin,
    yRange: yMax - yMin,
  };
}
