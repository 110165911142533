import styled from '@emotion/styled';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { ConnectionDataType } from '../../../model/connection.model';
import type { ConnectionGraphData } from '../../../model/connection.model';

export const wifiConnectedColor = stihlColor.blue;
export const deviceConnectedColor = stihlColor.stihlOrange;
export const cellularConnectedColor = stihlColor.forest;

const StyledTooltipContainer = styled.div`
  background-color: white;
  border: ${stihlColor.greyLight} 1.5px solid;
`;

const StyledParagraph = styled.p<{
  color?: string;
}>`
  margin: 0;
  padding: 4px 8px;
  color: ${(props) => props.color ?? 'black'};
`;

export const ConnectionHistoryTooltip = ({
  dataPointTimestamp,
  connectionData,
}: {
  dataPointTimestamp: number;
  connectionData: ConnectionGraphData[];
}): JSX.Element => {
  const { t } = useTranslation();
  const { toLocaleDateTimeString } = useDateFormatting();

  const connection = connectionData.find(
    (data) => data.timestamp === dataPointTimestamp,
  );

  if (!connection) {
    return <div />;
  }

  return (
    <StyledTooltipContainer data-testid="connectionHistoryTooltip">
      <StyledParagraph>
        {toLocaleDateTimeString(connection.timestamp)}
      </StyledParagraph>
      <StyledParagraph color={wifiConnectedColor}>
        {t('connectionHistory.wifiConnected')}
        {' : '}
        {connection[ConnectionDataType.WifiConnected]
          ? t('connectionHistory.connected')
          : t('connectionHistory.notConnected')}
      </StyledParagraph>
      <StyledParagraph color={deviceConnectedColor}>
        {t('connectionHistory.deviceConnected')}
        {' : '}
        {connection[ConnectionDataType.DeviceConnected]
          ? t('connectionHistory.connected')
          : t('connectionHistory.notConnected')}
      </StyledParagraph>
      <StyledParagraph color={cellularConnectedColor}>
        {t('connectionHistory.mobileConnection')}
        {' : '}
        {connection[ConnectionDataType.CellularConnected]
          ? t('connectionHistory.connected')
          : t('connectionHistory.notConnected')}
      </StyledParagraph>
      <StyledParagraph>
        {t('connectionHistory.providerId')}
        {' : '}
        {connection.providerId}
      </StyledParagraph>
    </StyledTooltipContainer>
  );
};
