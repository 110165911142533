import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import Pagination from '../../../app-shell/ui/pagination/pagination';
import QueryComponent from '../../../app-shell/ui/query-component';
import type { DeviceModel } from '../../../device/model';
import { CardHeader } from '../../../device/ui/card/generic-card/generic-card';
import { useMassAssignments } from '../../service/device-management-service/device-management-service';
import DeviceModelFilter from '../filters/device-model-filter';
import MassAssignmentsTable from './mass-assignments-table';

export const MassAssignments = (): JSX.Element => {
  const { t } = useTranslation();
  const [store] = useStore();
  const [deviceModel, setDeviceModel] = useState<DeviceModel>(
    store.deviceModels[0],
  );
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const [page, setPage] = useState(1);

  const massAssignments = useMassAssignments(deviceModel);

  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  return (
    <>
      <Card>
        <CardHeader>
          <Typography variant="h3">{t('massAssignments.cardTitle')}</Typography>
        </CardHeader>
        <CardContent>
          <div style={{ maxWidth: '250px' }}>
            <DeviceModelFilter
              deviceModel={deviceModel}
              setDeviceModel={setDeviceModel}
            />
          </div>
          <QueryComponent data={massAssignments}>
            {massAssignments.data && (
              <MassAssignmentsTable
                massAssignments={massAssignments.data}
                deviceModel={deviceModel}
                paginationFirstToDisplay={firstToDisplay}
                paginationLastToDisplay={lastToDisplay}
              />
            )}
          </QueryComponent>
        </CardContent>
      </Card>
      {massAssignments.data && massAssignments.data.length > 0 ? (
        <Pagination
          numberOfResults={massAssignments.data.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('massAssignments.pagination')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      ) : undefined}
    </>
  );
};

export default MassAssignments;
