import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconArrowDown from '../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { CardFieldType } from '../../../model/card-field.model';
import type { OperationDataCard as OperationDataCardValue } from '../../../model/card.model';
import CardField from '../../card-field/card-field';
import { CardBase, ContentWrapper } from '../generic-card/generic-card';

export const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

export const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const FlexAligner = styled.div`
  display: flex;
  align-items: center;
`;

export const UppercaseTypography = styled(Typography)`
  text-transform: uppercase;
`;

export const StyledMuiAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;

export type OperationDataCardProps = {
  cardData: OperationDataCardValue;
};

const workingTimeFields: Set<string> = new Set([
  CardFieldType.DeviceTotalWorkingTimeOfCuttingMotor,
]);

export const OperationDataCard = ({
  cardData,
}: OperationDataCardProps): JSX.Element => {
  const { t } = useTranslation();

  const directlyVisibleFieldsArray = cardData.cardFields.filter(
    (data) => !workingTimeFields.has(data.type),
  );

  const workingTimeOfMotorsFieldsArray = cardData.cardFields.filter((data) =>
    workingTimeFields.has(data.type),
  );

  return (
    <CardBase title={cardData.title}>
      <>
        {directlyVisibleFieldsArray.map((data) => (
          <ContentWrapper key={data.title}>
            <CardField value={data} />
          </ContentWrapper>
        ))}
        <MuiAccordion
          data-testid="operationDataAccordion"
          disableGutters
          sx={{
            '&.MuiPaper-root.MuiAccordion-root': {
              boxShadow: 0,
            },
            '&.MuiAccordion-root:before': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <MuiAccordionSummary
            data-testid="operationDataAccordionSummary"
            expandIcon={<StihlIconArrowDown />}
          >
            <UppercaseTypography variant="h6">
              {t('operationData.workingTimeOfMotors')}
            </UppercaseTypography>
          </MuiAccordionSummary>
          <StyledMuiAccordionDetails data-testid="operationDataAccordionDetails">
            {workingTimeOfMotorsFieldsArray.map((data) => (
              <ContentWrapper key={data.title}>
                <CardField value={data} />
              </ContentWrapper>
            ))}
          </StyledMuiAccordionDetails>
        </MuiAccordion>
      </>
    </CardBase>
  );
};

export default OperationDataCard;
