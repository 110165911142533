import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import type { FormikErrors, FormikTouched } from 'formik';
import { useMemo, type JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlSelect from '../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import type { StihlIdSupportedLocale } from '../../model/customer-invite.model';
import { useSupportedLocales } from '../../service/customer-service/customer-service';

const StyledSelectLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  inline-size: 100%;
`;
const StyledCountryLanguageGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export type CustomerCountryLanguageSelectProps = {
  languageValue: string;
  countryValue: string;
  onChange: (field: string, value?: string) => void;
  errors?: FormikErrors<{ language: string; country: string }>;
  touched?: FormikTouched<{ language: string; country: string }>;
};

const CustomerCountryLanguageSelect = ({
  countryValue,
  languageValue,
  onChange,
}: CustomerCountryLanguageSelectProps): JSX.Element => {
  const { t, i18n } = useTranslation();

  const supportedLocalesQueryResult = useSupportedLocales();

  function handleCountryChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    onChange('language', '');
    onChange('country', event.target.value);
    onChange(
      'language',
      supportedLocalesQueryResult.data?.[event.target.value][0]?.locale,
    );
  }

  const countryNames = useMemo(
    () =>
      new Intl.DisplayNames([i18n.language], {
        type: 'region',
      }),
    [i18n.language],
  );

  const languageNames = useMemo(
    () =>
      new Intl.DisplayNames([i18n.language], {
        type: 'language',
      }),
    [i18n.language],
  );

  const alphabeticalCountryCodeList = useMemo(
    () =>
      Object.keys(supportedLocalesQueryResult.data ?? {}).sort(
        (a: string, b: string) => {
          if (
            (countryNames.of(a.toLocaleUpperCase()) ?? '') >
            (countryNames.of(b.toLocaleUpperCase()) ?? '')
          ) {
            return 1;
          }
          return -1;
        },
      ),
    [countryNames, supportedLocalesQueryResult.data],
  );

  return (
    <StyledCountryLanguageGroup>
      <StyledSelectLabelGroup>
        <InputLabel htmlFor="country" variant="standard">
          {t('customerRegistration.country')}
        </InputLabel>
        <StihlSelect
          id="country"
          name="country"
          fullWidth
          value={countryValue}
          onChange={handleCountryChange}
          disabled={!supportedLocalesQueryResult.data}
          data-testid="countrySelector"
          inputProps={{ 'data-testid': 'country' }}
        >
          {alphabeticalCountryCodeList.map((countryCode: string) => (
            <MenuItem key={countryCode} value={countryCode}>
              {countryNames.of(countryCode.toLocaleUpperCase())}
            </MenuItem>
          ))}
        </StihlSelect>
      </StyledSelectLabelGroup>
      <StyledSelectLabelGroup>
        <InputLabel htmlFor="language" variant="standard">
          {t('customerRegistration.language')}
        </InputLabel>
        <StihlSelect
          id="language"
          name="language"
          fullWidth
          value={languageValue}
          onChange={(event) => onChange('language', event.target.value)}
          disabled={!supportedLocalesQueryResult.data}
          data-testid="languageSelector"
          inputProps={{ 'data-testid': 'language' }}
        >
          {supportedLocalesQueryResult.data?.[countryValue].map(
            (supportedLanguage: StihlIdSupportedLocale) => (
              <MenuItem
                key={supportedLanguage.locale}
                value={supportedLanguage.locale}
              >
                {languageNames.of(supportedLanguage.language)}
              </MenuItem>
            ),
          )}
        </StihlSelect>
      </StyledSelectLabelGroup>
    </StyledCountryLanguageGroup>
  );
};

export default CustomerCountryLanguageSelect;
