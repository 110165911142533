import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import type { ChangeEventHandler, FC } from 'react';
import { useCallback, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventLogFilterLabel,
  EventLogFilterSelect,
} from './event-log-filter-shared';

type EventLogFilterEventTypeProps = {
  eventTypesAvailable: string[];
  eventTypes: string[];
  setEventTypes: (eventTypes: string[]) => void;
};

const EventLogFilterEventType: FC<EventLogFilterEventTypeProps> = ({
  eventTypesAvailable,
  eventTypes,
  setEventTypes,
}) => {
  const eventTypesAvailableSorted = useMemo(
    () => [...eventTypesAvailable].sort(),
    [eventTypesAvailable],
  );

  const { t } = useTranslation();

  const labelId = useId();

  const handleSelectChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setEventTypes(event.target.value as unknown as string[]);
    },
    [setEventTypes],
  );

  return (
    <>
      <EventLogFilterLabel variant="standard" id={labelId}>
        {t('eventLog.filter.eventType.label')}
      </EventLogFilterLabel>
      <EventLogFilterSelect
        value={eventTypes}
        onChange={handleSelectChange}
        SelectProps={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          multiple: true,
          labelId,
          renderValue: (selectedEventTypes: unknown) =>
            !Array.isArray(selectedEventTypes) ||
            selectedEventTypes.length === 0
              ? t('eventLog.filter.eventType.placeholder')
              : t('eventLog.filter.eventType.value', {
                  count: selectedEventTypes.length,
                }),
        }}
      >
        {eventTypesAvailableSorted.map((eventTypeAvailable) => (
          <MenuItem key={eventTypeAvailable} value={eventTypeAvailable}>
            <Checkbox
              checked={eventTypes.includes(eventTypeAvailable)}
              aria-hidden
            />

            {eventTypeAvailable}
          </MenuItem>
        ))}
      </EventLogFilterSelect>
    </>
  );
};

export default EventLogFilterEventType;
