import styled from '@emotion/styled';
import type { JSX } from 'react';

const ContentWrapper = styled.div`
  h3 {
    font-size: 1em;
  }
`;

const ReleaseNotesHeading = styled.div`
  h2 {
    margin: 0;
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`;

const ReleaseNotesContent = (): JSX.Element => {
  return (
    <ContentWrapper>
      <ReleaseNotesHeading>
        <h2>IoT Service Portal 1.12.0</h2>
        <p>2024-09-25</p>
      </ReleaseNotesHeading>
      <h3>New Features</h3>
      <ul>
        <li>Filter event log by event type</li>
        <li>
          Filter connection history by time and new charts for a quick
          connection history overview
        </li>
        <li>Show if iMOW was reported as stolen</li>
        <li>Request certificate data for Connected Boxes</li>
        <li>Display enhanced certificate information for Connected Boxes</li>
      </ul>
      <h3>General Maintenance</h3>
      <ul>
        <li>Update of dependencies</li>
      </ul>
      <h3>Bug Fixes</h3>
      <ul>
        <li>Fix connection history handling with to many data points</li>
        <li>
          Fix duplicate &quot;unknown country&quot; in device management
          statistics
        </li>
      </ul>
    </ContentWrapper>
  );
};

export default ReleaseNotesContent;
