import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconPause from '../../../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconPlay from '../../../../../base/stihl-material-ui/icons/stihl-icon-play';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import CardFieldContent from '../card-field-content/card-field-content';

const CenteredStihlIconPlay = styled(StihlIconPlay)`
  align-self: center;
`;

const CenteredStihlIconPause = styled(StihlIconPause)`
  align-self: center;
`;

const StyledTypography = styled(Typography)`
  margin-left: 0.5rem;
`;

type CardFieldPartOfOrganizationProps = {
  title: string;
  value: boolean | null;
  icon?: ReactNode;
};

const CardFieldPartOfOrganization: FC<CardFieldPartOfOrganizationProps> = ({
  value,
  title,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={t(title)} icon={icon}>
      <StihlIconTextFlexbox>
        {value === true && (
          <>
            <CenteredStihlIconPlay data-testid="playIcon" />
            <StyledTypography>
              {t('deviceDetails.fleetManaged')}
            </StyledTypography>
          </>
        )}
        {value === false && (
          <>
            <CenteredStihlIconPause color="yellow" data-testid="pauseIcon" />
            <StyledTypography>
              {t('deviceDetails.notFleetManaged')}
            </StyledTypography>
          </>
        )}
        {value !== true && value !== false && (
          <>
            <StihlIconXFilled color={stihlColor.red} data-testid="xIcon" />
            <StyledTypography>
              {t('deviceDetails.noDataAvailable')}
            </StyledTypography>
          </>
        )}
      </StihlIconTextFlexbox>
    </CardFieldContent>
  );
};

export default CardFieldPartOfOrganization;
