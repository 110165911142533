/* eslint-disable @typescript-eslint/naming-convention */
export type Connection = {
  lastUpdatedTimestamp: string;
  cellularIsConnected: boolean | null;
  cellularNetworkInformation_providerId: string | null;
  deviceIsCurrentlyConnected: boolean | null;
  wifiIsConnected: boolean;
};

export enum ConnectionDataType {
  WifiConnected = 'wifiConnected',
  DeviceConnected = 'deviceConnected',
  CellularConnected = 'cellularConnected',
}

export type ConnectionGraphData = {
  [ConnectionDataType.CellularConnected]: number;
  [ConnectionDataType.DeviceConnected]: number;
  [ConnectionDataType.WifiConnected]: number;
  timestamp: number;
  providerId: string;
};
/* eslint-enable @typescript-eslint/naming-convention */
