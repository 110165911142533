import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';

const StyledTypography = styled(Typography)`
  padding-block-start: 1.5rem;
`;

export type FooterModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const LegalNoticeModal = ({ open, setOpen }: FooterModalProps): JSX.Element => {
  const { t } = useTranslation();

  function handleClose(): void {
    setOpen(false);
  }

  return (
    <StihlModal
      open={open}
      onClose={handleClose}
      title={t('footer.legalNotice.title')}
      data-testid="legalNoticeModal"
      noButtons
    >
      <>
        <StyledTypography>{t('footer.legalNotice.provider')}</StyledTypography>
        <StyledTypography>
          {t('footer.legalNotice.address.name')}
        </StyledTypography>
        <StyledTypography>
          {t('footer.legalNotice.address.street')} <br />
          {t('footer.legalNotice.address.city')} <br />
          {t('footer.legalNotice.address.telephoneNumber')}
          <br />
          {t('footer.legalNotice.address.email')}
        </StyledTypography>

        <StyledTypography>{t('footer.legalNotice.execBoard')}</StyledTypography>

        <Typography>
          {t('footer.legalNotice.execBoardMembers.chairman')}
        </Typography>
        <Typography>
          {t('footer.legalNotice.execBoardMembers.headOfFinance')}
        </Typography>
        <Typography>
          {t('footer.legalNotice.execBoardMembers.headOfHR')}
        </Typography>
        <Typography>
          {t('footer.legalNotice.execBoardMembers.headOfDevelopment')}
        </Typography>
        <Typography>
          {t('footer.legalNotice.execBoardMembers.headOfMaterials')}
        </Typography>
        <StyledTypography>
          {t('footer.legalNotice.commercialRegister')}
        </StyledTypography>

        <StyledTypography>{t('footer.legalNotice.vatId')}</StyledTypography>

        <StyledTypography>
          {t('footer.legalNotice.dataProtectionOfficer')}
        </StyledTypography>

        <Typography>
          {t('footer.legalNotice.address.name')} <br />
          {t('footer.legalNotice.dpoAddress.street')} <br />
          {t('footer.legalNotice.dpoAddress.city')} <br />
          {t('footer.legalNotice.dpoAddress.email')}
        </Typography>

        <StyledTypography>{t('footer.legalNotice.date')}</StyledTypography>
      </>
    </StihlModal>
  );
};

export default LegalNoticeModal;
