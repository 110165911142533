import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  JobStateValue,
  CardFieldDeviceJobStateValue,
} from '../../../model/card-field.model';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldJobProps = {
  name: string;
  value: CardFieldDeviceJobStateValue;
};

const CardFieldJob: FC<CardFieldJobProps> = ({ name, value }) => {
  const stateValue: JobStateValue | undefined = value.value;
  const { t } = useTranslation();
  return (
    <CardFieldContent name={name}>
      <Grid container>
        {Boolean(stateValue) && (
          <>
            <Typography>{t('mower.mowingZoneId')}</Typography>
            <Typography>{t('mower.startEndTime')}</Typography>
          </>
        )}
        <Fragment key={value.state}>
          <Typography>{value.state}</Typography>
          {Boolean(stateValue) && (
            <>
              <Typography>
                {(t('statusTexts.inZone'), stateValue?.mowingZoneId)}, cyclic{' '}
                {stateValue?.isNonCyclic ? t('off') : t('on')}
              </Typography>
              <Typography>
                {stateValue?.startTime} - {stateValue?.endTime}
              </Typography>
            </>
          )}
        </Fragment>
      </Grid>
    </CardFieldContent>
  );
};

export default CardFieldJob;
