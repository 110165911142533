export function base64StringToUint8Array(input: string): Uint8Array {
  const inputDecoded = atob(input);

  return Uint8Array.from(
    { length: inputDecoded.length },
    (_, index) => inputDecoded.codePointAt(index) as number,
  );
}

export function uint8ArrayToBase64String(input: Uint8Array): string {
  const valueStringified = String.fromCodePoint(...input);

  return btoa(valueStringified);
}
