import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

export function useLocalStorage<T>(
  storageKey: string,
  fallbackState: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(storageKey);

    return storedValue ? (JSON.parse(storedValue) as T) : fallbackState;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
}
