import type { FC } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { useStihlTheme } from '../../../../../base/stihl-material-ui/theme/stihl-theme-provider';

type GnssWorkingAreaMapScaleProps = {
  x: number;
  y: number;
  scaleFactor: number;
  size: number;
};

const CardFieldGnssWorkingAreaMapScale: FC<GnssWorkingAreaMapScaleProps> = ({
  x,
  y,
  scaleFactor,
  size,
}) => {
  const { i18n, t } = useTranslation();
  const theme = useStihlTheme();
  const titleId = useId();

  return (
    <g
      transform={`translate(${x} ${y + 15 * scaleFactor})`}
      aria-labelledby={titleId}
      role="graphics-object"
    >
      <title
        // eslint-disable-next-line react/forbid-dom-props
        id={titleId}
      >
        {t('workingArea.gnssWorkingArea.scaleLabel')}
      </title>

      <path
        d={`M0 ${3 * scaleFactor} l0 ${-6 * scaleFactor} M0 0 L ${size} 0 m 0 ${3 * scaleFactor} l 0 ${-6 * scaleFactor}`}
        stroke={theme.palette.grey['400']}
        strokeWidth="1px"
        vectorEffect="non-scaling-stroke"
      />
      <text
        x={size + 5 * scaleFactor}
        y="0"
        dominantBaseline="central"
        fontSize={15 * scaleFactor}
      >
        {i18n.format(size, 'number(style: unit; unit: meter)')}
      </text>
    </g>
  );
};

export default CardFieldGnssWorkingAreaMapScale;
