import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import StihlIconLegend from '../../../../../base/stihl-material-ui/icons/stihl-icon-legend';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { StihlThemeColor } from '../../../../../base/stihl-material-ui/theme/stihl-theme-colors';
import type { ErrorValue } from '../../../model/card-field.model';
import { DeviceTabsContext } from '../../tabs/device-tabs.context';
import CardFieldContent from '../card-field-content/card-field-content';

const StyledLink = styled.button`
  color: ${stihlColor.blueDark};
  text-decoration: underline;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const ErrorPriority: FC<{ priority: number | undefined }> = ({
  priority,
}) => {
  const priorityColor: StihlThemeColor =
    priority === 1
      ? 'priority.priority1'
      : priority === 2
        ? 'priority.priority2'
        : priority === 3
          ? 'priority.priority3'
          : 'grey.500';

  return <StihlIconLegend color={priorityColor} />;
};

type CardFieldErrorProps = {
  name: string;
  value: ErrorValue[];
};

const TooltipIcon: FC = () => {
  const { t } = useTranslation();

  return (
    <StihlTooltip
      title={t('deviceDetails.errorsAndServicesTooltip')}
      placement="bottom"
      data-testid="activeErrorsTooltip"
    >
      <StihlIconInfo />
    </StihlTooltip>
  );
};

type NoErrorTextProps = {
  name: string;
};
const NoErrorText: FC<NoErrorTextProps> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <CardFieldContent name={name}>
      <StihlIconTextFlexbox>
        <StihlIconLegend color={stihlColor.green} />
        <Typography sx={{ marginLeft: '0.5rem' }}>
          {t('deviceDetails.noErrorsActive')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardFieldContent>
  );
};

const ErrorDateTypographyStyled = styled(Typography)`
  color: ${stihlColor.fontGrey};
`;

const ErrorText: FC<CardFieldErrorProps> = ({ name, value }) => {
  const { openTab } = useContext(DeviceTabsContext);
  const { toLocaleDateTimeString } = useDateFormatting();

  function handleClick(): void {
    openTab('tabs.dtcCodes');
  }
  return (
    <CardFieldContent name={name} icon={<TooltipIcon />}>
      {value.map((data: ErrorValue, index) => (
        // Code might not be unique, so the index has to be used instead
        // eslint-disable-next-line react/no-array-index-key
        <StihlIconTextFlexbox key={index}>
          <div>
            <ErrorPriority priority={data.priority} />
          </div>
          <div style={{ marginLeft: '0.5rem' }}>
            <Typography>
              <StyledLink onClick={handleClick}>{data.code}</StyledLink> -{' '}
              {data.requiredAction}
              <ErrorDateTypographyStyled>
                {' '}
                {toLocaleDateTimeString(new Date(data.activeSince))}
              </ErrorDateTypographyStyled>
            </Typography>
          </div>
        </StihlIconTextFlexbox>
      ))}
    </CardFieldContent>
  );
};

const CardFieldError: FC<CardFieldErrorProps> = ({ name, value }) => {
  return value.length === 0 ? (
    <NoErrorText name={name} />
  ) : (
    <ErrorText name={name} value={value} />
  );
};

export default CardFieldError;
