import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTableHeader from '../../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';

type EventLogTableHeaderProps = {
  rowCount: number;
};

type EventLogColumns = {
  timestamp: string;
  type: string;
  value: string;
};

type EventLogHeadCells = {
  id: keyof EventLogColumns;
  label: string;
  isSortable: boolean;
};

export const EventLogTableHeader = ({
  rowCount,
}: EventLogTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const headCells: EventLogHeadCells[] = [
    {
      id: 'timestamp',
      label: t('eventLog.timestamp'),
      isSortable: false,
    },
    {
      id: 'type',
      label: t('eventLog.type'),
      isSortable: false,
    },
    {
      id: 'value',
      label: t('eventLog.value'),
      isSortable: false,
    },
  ];

  return (
    <StihlTableHeader<EventLogColumns>
      rowCount={rowCount}
      headCells={headCells}
    />
  );
};
