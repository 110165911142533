/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import type { JSX } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import StihlIconArrowDown from '../../../../../../base/stihl-material-ui/icons/stihl-icon-arrow-down';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { EcuData, EcuDetailsData } from '../../../../model/device.model';

const StyledArrowIcon = styled(StihlIconArrowDown)`
  transform: rotate(180deg);
`;

const StyledHeaderTableCell = styled(TableCell)`
  color: ${stihlColor.fontGrey};
`;

const mapDisplayValue = (valueWithUnit: string): string => {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/u.test(valueWithUnit)) {
    return new Date(valueWithUnit).toLocaleDateString();
  }
  return valueWithUnit;
};

const DiagnosisDetailsTableRow = ({
  ecuDetailsData,
}: {
  ecuDetailsData: EcuDetailsData;
}): JSX.Element => {
  const { toLocaleDateTimeString } = useDateFormatting();

  return (
    <TableRow
      key={ecuDetailsData.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      data-testid="diagnosisDetailsTableRow"
    >
      <TableCell component="th" scope="row">
        {toLocaleDateTimeString(new Date(ecuDetailsData.timeStamp))}
      </TableCell>
      <TableCell>{ecuDetailsData.name}</TableCell>
      <TableCell>{mapDisplayValue(ecuDetailsData.valueWithUnit)}</TableCell>
    </TableRow>
  );
};

const DiagnosisDetailsTable = ({
  ecuDetailsDataArray,
}: {
  ecuDetailsDataArray: EcuDetailsData[];
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TableContainer data-testid="diagnosisDetailsTable">
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeaderTableCell>
              <b> {t('diagnosisData.timestamp')}</b>
            </StyledHeaderTableCell>
            <StyledHeaderTableCell>
              <b> {t('diagnosisData.name')}</b>
            </StyledHeaderTableCell>
            <StyledHeaderTableCell>
              <b> {t('diagnosisData.value')}</b>
            </StyledHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ecuDetailsDataArray.map((ecuDetails) => (
            <DiagnosisDetailsTableRow
              key={ecuDetails.id}
              ecuDetailsData={ecuDetails}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DiagnosisTableRow = ({ ecuData }: { ecuData: EcuData }): JSX.Element => {
  const [open, setOpen] = useState(false);

  function handleOnOpenDetailsRow(): void {
    setOpen(!open);
  }
  return (
    <>
      <TableRow
        key={ecuData.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        hover
        style={{ cursor: 'pointer' }}
        onClick={handleOnOpenDetailsRow}
        data-testid="diagnosisTableRow"
      >
        <TableCell component="th" scope="row">
          {ecuData.id}
        </TableCell>
        <TableCell>{ecuData.errors}</TableCell>
        <TableCell>
          {open ? <StyledArrowIcon /> : <StihlIconArrowDown />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DiagnosisDetailsTable ecuDetailsDataArray={ecuData.data} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const DiagnosisTable = ({
  ecuDataArray,
}: {
  ecuDataArray: EcuData[];
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TableContainer data-testid="diagnosisTable">
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeaderTableCell>
              <b> {t('diagnosisData.id')} </b>
            </StyledHeaderTableCell>
            <StyledHeaderTableCell>
              <b> {t('diagnosisData.errors')}</b>
            </StyledHeaderTableCell>
            <StyledHeaderTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {ecuDataArray.map((ecuData) => (
            <DiagnosisTableRow key={ecuData.id} ecuData={ecuData} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

/* eslint-enable react/jsx-max-depth */
