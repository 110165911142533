import type { ErrorValue } from '../../device/model/card-field.model';
import type { DeviceModel, DeviceType } from '../../device/model/device.model';

export type DeviceCountries = {
  countryCode: string | number | undefined;
  numRegistered: number;
  numCurrentlyConnectedViaWifi: number;
  numCurrentlyConnectedViaCellular: number;
  numWithActiveErrors: number;
  numConnectedViaEthernet: number;
};

export type SwVersions = {
  numAssigned: number;
  numDownloadedOnly: number;
  numInstalled: number;
  numUpdateErrors: number;
  targetSwVersion: string;
};

export type DeviceStatistics = {
  countryStatistics: DeviceCountries[];
  softwareUpdateStatistics: {
    numRegisteredDevices: number;
    swVersions: SwVersions[];
  } | null;
};

export type DeviceStatus = {
  serialNumber: string;
  deviceId: string;
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  softwareVersion: string | null;
  isConnected: boolean | null;
  processingChain: string | null;
  errors: ErrorValue[];
};

export type DeviceStatusColumns = {
  serialNumber: string;
  deviceId: string;
  deviceModel: string;
  deviceType: string;
  softwareVersion: string;
  isConnected: string;
  processingChain: string;
  errors: ErrorValue[];
};

export type DeviceStatusHeadCells = {
  id: keyof DeviceStatusColumns;
  label: string;
  isSortable: boolean;
};

export enum DeviceStatusDisplayType {
  ErrorDevices = 'errorDevices',
  SoftwareVersion = 'softwareVersion',
}
