import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import type { JSX, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonOutlined from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined';
import StihlButtonOutlinedLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-outlined-loading';
import StihlModal from '../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlIconDemo from '../../../../../base/stihl-material-ui/icons/stihl-icon-demo';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import type { DeviceModel } from '../../../model';
import type { DemoModeCard as DemoModeCardValue } from '../../../model/card.model';
import type { Meta } from '../../../model/page.model';
import { changeSetting } from '../../../service/device-api-client/device-api-client';

export const StyledCardContent = styled(CardContent)`
  padding: 1rem;
`;

export const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-block-end: 4px solid ${stihlColor.stihlOrange};
`;

export const FlexAligner = styled.div`
  display: flex;
  align-items: center;
`;

export type DemoModeModalProps = {
  deviceId: string;
  deviceModel: DeviceModel;
  isModalOpen: boolean;
  isActiveDemoMode: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const DemoModeModal = ({
  deviceId,
  deviceModel,
  isModalOpen,
  isActiveDemoMode,
  setModalOpen,
}: DemoModeModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setAlert] = useAlertStore();
  const isSettingValue = !isActiveDemoMode;
  const isSettingName = 'deviceIsDemoModeActive';
  const actionMessage = isActiveDemoMode
    ? t('supportFunctions.deactivate')
    : t('supportFunctions.activate');

  function handleClose(): void {
    setModalOpen(false);
  }

  function handleChangeDemoMode(): void {
    setIsLoading(true);
    void changeSetting(deviceId, deviceModel, isSettingName, isSettingValue)
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          severity: 'success',
          message: t('supportFunctions.demoModeChangeSuccess'),
        });
        return setModalOpen(false);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          severity: 'error',
          message: t('supportFunctions.demoModeChangeError'),
        });
        setIsLoading(false);
      });
  }

  const modalTitle = isActiveDemoMode
    ? t('supportFunctions.deactivateHeading', {
        setting: t('supportFunctions.demoMode'),
      })
    : t('supportFunctions.activateHeading', {
        setting: t('supportFunctions.demoMode'),
      });

  return (
    <StihlModal
      open={isModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      title={modalTitle}
      actionButtonText={actionMessage}
      handleActionClick={handleChangeDemoMode}
      data-testid="demoModeModal"
    >
      <FlexAligner>
        <StihlIconInfo />
        <Typography component="span" variant="h5" sx={{ marginLeft: '0.5rem' }}>
          {t('supportFunctions.demoModeHint')}
        </Typography>
      </FlexAligner>
    </StihlModal>
  );
};

const HandleDemoModeButton = ({
  meta,
  isActive,
}: {
  meta: Meta;
  isActive: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, _setIsLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const actionMessage = isActive
    ? t('supportFunctions.deactivate')
    : t('supportFunctions.activate');

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  return (
    <div>
      {isLoading ? (
        <StihlButtonOutlinedLoading
          onClick={handleModalOpen}
          loading={isLoading}
        >
          {actionMessage}
        </StihlButtonOutlinedLoading>
      ) : (
        <StihlButtonOutlined
          onClick={handleModalOpen}
          data-testid="openModalButton"
        >
          {actionMessage}
        </StihlButtonOutlined>
      )}
      <DemoModeModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        deviceId={meta.deviceId}
        deviceModel={meta.deviceModel}
        isActiveDemoMode={isActive}
      />
    </div>
  );
};

export type DemoModeCardProps = {
  meta: Meta;
  cardData: DemoModeCardValue;
};

export const DemoModeCard = ({
  meta,
  cardData,
}: DemoModeCardProps): JSX.Element => {
  const { t } = useTranslation();
  const isActive = cardData.cardFields[0].value;

  return (
    <MuiCard data-testid="demoModeCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
        <HandleDemoModeButton meta={meta} isActive={isActive} />
      </CardHeader>
      <StyledCardContent>
        <IconAligner>
          {isActive && <StihlIconDemo />}
          <Typography>
            {`${t('supportFunctions.demoModeIs')} `}

            {isActive
              ? t('supportFunctions.activeWithDot')
              : t('supportFunctions.notActiveWithDot')}
          </Typography>
        </IconAligner>

        <Typography>{t('supportFunctions.demoModeHint')}</Typography>
        <br />
        <FlexAligner>
          <StihlIconInfo />
          <Typography
            component="span"
            variant="h5"
            sx={{ marginLeft: '0.5rem' }}
            data-testid="demoModeActivationInfo"
          >
            {t('supportFunctions.demoModeActivationTime')}
          </Typography>
        </FlexAligner>
      </StyledCardContent>
    </MuiCard>
  );
};

export default DemoModeCard;
