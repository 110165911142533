import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconDotsConnected from '../../../../../base/stihl-material-ui/icons/stihl-icon-dots-connected';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldTeamMowerProps = {
  title: string;
  value?: boolean;
};

const CardFieldTeamMower: FC<CardFieldTeamMowerProps> = ({ value, title }) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={title}>
      <StihlIconTextFlexbox>
        {value ? (
          <StihlIconDotsConnected color="primary" data-testid="iconOrange" />
        ) : (
          <StihlIconDotsConnected color="grey" data-testid="iconGrey" />
        )}

        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('deviceDetails.settings.isTeamMower')
            : t('deviceDetails.settings.isNoTeamMower')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardFieldContent>
  );
};

export default CardFieldTeamMower;
