import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';

const IconAligner = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CardFieldCustomerDetailsError: FC = () => {
  const { t } = useTranslation();

  return (
    <IconAligner>
      <StihlIconXFilled color="error" />
      <Typography>{t('customerDetails.noCustomerDataFound')}</Typography>
    </IconAligner>
  );
};

export default CardFieldCustomerDetailsError;
