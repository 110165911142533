import styled from '@emotion/styled';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconClose from '../../icons/stihl-icon-close';
import { stihlColor } from '../../theme/stihl-style-guide';
import StihlButtonOutlined from '../stihl-button/stihl-button-outlined';
import StihlButtonSecondaryLoading from '../stihl-button/stihl-button-secondary-loading';

const ModalStyled = styled(Dialog)`
  .MuiDialog-paper {
    display: flex;
    flex-direction: column;
    align-content: start;
    inline-size: 50vw;
    min-inline-size: 370px;
    max-inline-size: 750px;
    min-block-size: 200px;
    max-block-size: 70%;
    padding: 2.5rem 3.5rem;
    overflow: hidden;
    background-color: ${stihlColor.white};
  }
`;

const ModalContent = styled.div`
  flex-grow: 1;
  margin: 1rem 0;
  overflow-y: auto;
`;

const CloseButtonPositioner = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  background-color: ${stihlColor.black};
`;

const ActionButtonsPositioner = styled.div`
  inset-block-end: 1rem;
  display: flex;
  justify-content: space-between;
  justify-self: end;
`;

export type StihlModalProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  actionButtonText?: string;
  cancelButtonText?: string;
  handleActionClick?: () => void;
  handleOnClose?: () => void;
  isLoading?: boolean;
  noButtons?: boolean;
  isDisabled?: boolean;
  noCloseButton?: boolean;
};

const StihlModal: FC<DialogProps & StihlModalProps> = ({
  title,
  open,
  onClose,
  actionButtonText,
  cancelButtonText,
  handleActionClick,
  handleOnClose,
  isLoading = false,
  noButtons = false,
  isDisabled = false,
  noCloseButton = false,
  ...props
}) => {
  const { t } = useTranslation();
  const titleId = useId();

  return (
    <ModalStyled
      open={open}
      onClose={onClose}
      {...props}
      aria-labelledby={titleId}
    >
      {noCloseButton ? null : (
        <CloseButtonPositioner>
          <IconButton
            onClick={handleOnClose ?? onClose}
            data-testid="modalClose"
            size="large"
          >
            <StihlIconClose color="white" />
          </IconButton>
        </CloseButtonPositioner>
      )}
      <Typography variant="h3" component="h1" id={titleId}>
        {title}
      </Typography>
      <ModalContent>{props.children}</ModalContent>
      {!noButtons && (
        <ActionButtonsPositioner>
          <StihlButtonOutlined
            onClick={handleOnClose ?? onClose}
            data-testid="modalCancel"
          >
            {cancelButtonText ?? t('cancel')}
          </StihlButtonOutlined>

          <StihlButtonSecondaryLoading
            loading={isLoading}
            disabled={isDisabled}
            onClick={handleActionClick}
            data-testid="modalAction"
          >
            {actionButtonText}
          </StihlButtonSecondaryLoading>
        </ActionButtonsPositioner>
      )}
    </ModalStyled>
  );
};

export default StihlModal;
