/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { Dispatch, SetStateAction, JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import type { ChipType } from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import {
  FilterParameterValue,
  StihlChipContainer,
} from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import type {
  ItemType,
  DeviceType,
  DeviceModel,
} from '../../../../device/model';
import DeviceModelFilter from '../../filters/device-model-filter';
import ProcessingChainFilter from '../../filters/processing-chain-filter';
import DevicePropertyFilter from './device-property-filter';

const CardWrapper = styled(Card)`
  min-block-size: 270px;
  padding: 0.75rem;
`;

export type DeviceManagementFilterProps = {
  chips: ChipType[];
  setChips: Dispatch<SetStateAction<ChipType[]>>;
  deviceTypes: ItemType<DeviceType>[];
  processingChain: string | undefined;
  setProcessingChain: (processingChain: string | undefined) => void;
  deviceModel: DeviceModel | undefined;
  deviceModelCallback: (model: DeviceModel | undefined) => void;
  handleSearchCallback: () => void;
  isSearchLoading: boolean;
};

// eslint-disable-next-line max-lines-per-function
const DeviceManagementFilter = ({
  chips,
  setChips,
  deviceTypes,
  deviceModelCallback,
  processingChain,
  setProcessingChain,
  deviceModel,
  handleSearchCallback,
  isSearchLoading,
}: DeviceManagementFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const [isInvalidChipInput, setInvalidChipInput] = useState(false);

  const checkDuplicate = useCallback(
    (filter: FilterParameterValue): boolean => {
      const chipFilter = chips.find(
        (chip) =>
          chip.filter.toString() !== 'serialNumber' &&
          chip.filter.toString() !== 'deviceId' &&
          chip.filter === filter,
      );
      return Boolean(chipFilter);
    },
    [chips],
  );

  const addChip = useCallback(
    (chipData: ChipType): void => {
      const { filter, searchTerm, value, chipRenderText } = chipData;
      if (checkDuplicate(filter)) {
        setInvalidChipInput(true);
        return;
      }
      const newChip: ChipType = {
        filter,
        searchTerm,
        value,
        chipRenderText,
      };
      setInvalidChipInput(false);
      setChips([...chips, newChip]);
    },
    [checkDuplicate, chips, setChips],
  );

  const handleDeviceModelChange = useCallback(
    (model: DeviceModel | undefined): void => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (model) {
        addChip({
          filter: FilterParameterValue.DeviceModel,
          searchTerm: model,
        });
      }
      deviceModelCallback(model);
    },
    [addChip, deviceModelCallback],
  );

  const handleProcessingChainChange = useCallback(
    (selectedProcessingChain: string | undefined): void => {
      if (selectedProcessingChain) {
        addChip({
          filter: FilterParameterValue.ProcessingChain,
          searchTerm: selectedProcessingChain,
        });
      }
      setProcessingChain(selectedProcessingChain);
    },
    [addChip, setProcessingChain],
  );

  useEffect(() => {
    if (
      !chips.some((chip) => chip.filter === FilterParameterValue.DeviceModel)
    ) {
      handleDeviceModelChange(undefined);
    }
  }, [chips, handleDeviceModelChange]);

  useEffect(() => {
    if (
      !chips.some(
        (chip) => chip.filter === FilterParameterValue.ProcessingChain,
      )
    ) {
      handleProcessingChainChange('');
    }
  }, [chips, handleProcessingChainChange]);

  return (
    <CardWrapper data-testid="deviceManagementFilter">
      <Grid container>
        <Grid item xs={12}>
          <Typography>{t('deviceManagementFilter.instruction')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} style={{ display: 'flex' }}>
            <Grid item xl={2} xs={6}>
              <DeviceModelFilter
                deviceModel={deviceModel}
                setDeviceModel={handleDeviceModelChange}
                disabled={chips.some(
                  (chip) => chip.filter === FilterParameterValue.DeviceModel,
                )}
                hasEmptyOption
              />
            </Grid>
            <Grid item xl={2} xs={6}>
              <ProcessingChainFilter
                processingChain={processingChain}
                setProcessingChain={handleProcessingChainChange}
                disabled={chips.some(
                  (chip) =>
                    chip.filter === FilterParameterValue.ProcessingChain,
                )}
                hasEmptyOption
              />
            </Grid>
            <Grid item xl={7} xs={12}>
              <DevicePropertyFilter
                deviceTypes={deviceTypes}
                addChip={addChip}
                chips={chips}
                setInvalidChipInput={setInvalidChipInput}
                deviceModel={deviceModel}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StihlChipContainer
            chips={chips}
            setChips={setChips}
            isInvalidInput={isInvalidChipInput}
            setInvalidInput={setInvalidChipInput}
          />
          <StihlButtonSecondaryLoading
            loading={isSearchLoading}
            onClick={handleSearchCallback}
            disabled={
              !chips.some(
                (chip) => chip.filter === FilterParameterValue.DeviceModel,
              )
            }
            data-testid="searchManagedDevicesButton"
          >
            {t('deviceManagementFilter.startSearch')}
          </StihlButtonSecondaryLoading>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default DeviceManagementFilter;

/* eslint-enable react/jsx-max-depth */
