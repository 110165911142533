import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldDidToSemanticVersionProps = {
  name: string;
  value: string;
};

const CardFieldDidToSemanticVersion: FC<CardFieldDidToSemanticVersionProps> = ({
  name,
  value,
}) => {
  // DID = Diagnostic ID
  // DID calculation:  let did=$major*$((2 ** 11))+$minor*$((2 ** 6))+$bugfix

  const didAsNumber = Number(value);

  // last 6 bit are bugfix
  const bugfix = didAsNumber & 63;
  // then there are 5 bits of minor
  const minor = (didAsNumber >> 6) & 31;
  // then there are 5 bits of major
  const major = (didAsNumber >> 11) & 31;

  const semanticVersion = `${major}.${minor}.${bugfix}`;

  return (
    <CardFieldContent name={name}>
      <Typography data-testid="initialSoftwareValue">
        {semanticVersion} ({value})
      </Typography>
    </CardFieldContent>
  );
};

export default CardFieldDidToSemanticVersion;
