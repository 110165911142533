import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlModal from '../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { LoginProvider } from '../../../app-authentication/service/authentication-session-store';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import type { DeviceModel } from '../../model';
import { useRequestReadOnlyPermissions } from '../../service/device-service/device-service';

const StyledTypography = styled(Typography)`
  margin-block-end: 1rem;
`;

export type ExitModalProps = {
  open: boolean;
  onClose: () => void;
  deviceId: string | undefined;
  deviceModel: DeviceModel | undefined;
};

const ExitModal: FC<ExitModalProps> = ({
  open,
  onClose,
  deviceId,
  deviceModel,
}) => {
  const { t } = useTranslation();
  const [__, setAlert] = useAlertStore();
  const [store, _] = useStore();

  const requestReadOnlyPermissions = useRequestReadOnlyPermissions(
    deviceId ?? '',
    deviceModel ?? '',
  );

  function openLink(): void {
    let role = '';
    if (store.authType === LoginProvider.DealerWebSso) {
      role = 'support-dealer';
    } else if (store.authType === LoginProvider.AzureAd) {
      role = 'support-level';
    }
    window.open(`${process.env.REACT_APP_CUSTOMER_APP_BASE_URL}?role=${role}`);
  }

  function handleAccept(): void {
    requestReadOnlyPermissions.mutate(undefined, {
      onSuccess: () => {
        setAlert({
          isOpen: true,
          message: t('jumpToApp.requestSuccessful'),
          severity: 'success',
        });
        openLink();
        onClose();
      },
      onError: (error) => {
        if (isAxiosError(error) && error.response?.status === 409) {
          setAlert({
            isOpen: true,
            // eslint-disable-next-line no-secrets/no-secrets
            message: t('jumpToApp.requestFailedConflict'),
            severity: 'error',
          });
        } else {
          setAlert({
            isOpen: true,
            message: t('jumpToApp.requestFailed'),
            severity: 'error',
          });
        }
      },
    });
  }

  return (
    <StihlModal
      open={open}
      onClose={onClose}
      title={t('jumpToApp.switch')}
      actionButtonText={t('accept')}
      handleActionClick={handleAccept}
      isLoading={requestReadOnlyPermissions.isPending}
      data-testid="exitModal"
    >
      <>
        <StyledTypography>
          {t('jumpToApp.readPermissionsDuration')}
        </StyledTypography>
        <StyledTypography>{t('jumpToApp.hint')}</StyledTypography>
      </>
    </StihlModal>
  );
};

export default ExitModal;
