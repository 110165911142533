import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

type CardFieldTextLineProps = { value: string };

const CardFieldTextLine: FC<CardFieldTextLineProps> = ({ value }) => {
  const { t } = useTranslation();
  return <Typography style={{ paddingBottom: '1rem' }}>{t(value)}</Typography>;
};

export default CardFieldTextLine;
