import styled from '@emotion/styled';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconValidFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-valid-filled';
import StihlIconXFilled from '../../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';

const StyledVerificationChip = styled('div', {
  shouldForwardProp: (propName) => propName !== 'isVerified',
})<{ isVerified: boolean | undefined }>`
  display: flex;
  gap: 5px;
  align-items: center;
  block-size: 1.5rem;
  padding: 0.25rem 0.5rem;
  color: ${({ isVerified }) =>
    isVerified === true
      ? stihlColor.green
      : isVerified === false
        ? stihlColor.red
        : stihlColor.greyDark};
`;

type CustomerDetailsVerificationChipProps = {
  isVerified: boolean | undefined;
};

const CustomerDetailsVerificationChip: FC<
  CustomerDetailsVerificationChipProps
> = ({ isVerified }) => {
  const { t } = useTranslation();

  return (
    <StyledVerificationChip isVerified={isVerified}>
      {isVerified === true ? (
        <>
          <StihlIconValidFilled /> {t('customerDetails.verified')}
        </>
      ) : isVerified === false ? (
        <>
          <StihlIconXFilled /> {t('customerDetails.unverified')}
        </>
      ) : (
        <>
          <StihlIconXFilled /> {t('customerDetails.unknown')}
        </>
      )}
    </StyledVerificationChip>
  );
};

export default CustomerDetailsVerificationChip;
