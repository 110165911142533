import type { DeviceModel } from './device.model';
import type { Page } from './page.model';

export enum SearchParameterValues {
  Email = 'email',
  SerialNumber = 'serial number',
  Imei = 'IMEI',
  Imsi = 'IMSI',
  DeviceId = 'deviceId',
  OrganizationId = 'organizationId',
  OrganizationEmail = 'organizationEmail',
}

export type SearchObject = {
  deviceModel: DeviceModel | string | undefined;
  selector: SearchParameterValues | undefined;
  search: string;
};

export type DeviceSearchResult = Page;

export type DeviceSearchState = {
  data?: DeviceSearchResult;
  isLoading: boolean;
  isError: boolean;
};
