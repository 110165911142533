import styled from '@emotion/styled';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconXFilled from '../../../../../base/stihl-material-ui/icons/stihl-icon-x-filled';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import type { FleetManagementCard as FleetManagementCardValue } from '../../../model/card.model';
import type { Meta } from '../../../model/page.model';
import CardField from '../../card-field/card-field';
import {
  CardHeader,
  ContentWrapper,
  StyledCardContent,
} from '../generic-card/generic-card';

export const IconAligner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

type FleetManagementCardProps = {
  cardData: FleetManagementCardValue;
  meta: Meta;
};

const FleetmanagementCard: FC<FleetManagementCardProps> = ({
  meta,
  cardData,
}) => {
  const { t } = useTranslation();

  return (
    <MuiCard data-testid="fleetmanagementCard">
      <CardHeader>
        <Typography variant="h3">{t(cardData.title)}</Typography>
      </CardHeader>
      <StyledCardContent>
        {meta.hasFleetError && (
          <IconAligner>
            <StihlIconXFilled color={stihlColor.red} />
            <Typography>{t('deviceDetails.noFleetData')}</Typography>
          </IconAligner>
        )}
        {cardData.cardFields.map((data) => (
          <ContentWrapper key={data.title}>
            <CardField value={data} />
          </ContentWrapper>
        ))}
      </StyledCardContent>
    </MuiCard>
  );
};
export default FleetmanagementCard;
