import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import type { FC, ReactNode } from 'react';
import StihlIconBin from '../../icons/stihl-icon-bin';
import { stihlColor } from '../../theme/stihl-style-guide';

const StyledChipContainer = styled(Container)`
  @media (min-width: 600px) {
    padding-inline: 0;
  }

  @media (min-width: 1200px) {
    max-inline-size: none;
    padding-inline: 0;
  }
`;

const StyledClearChip = styled(Chip)`
  margin-inline-end: 0.75rem;
  padding-inline-start: 0.33rem;
  float: inline-end;
  background-color: ${stihlColor.greyLight};

  > .MuiChip-label {
    line-height: normal;
  }
`;

const StyledChip = styled(Chip)`
  margin-inline-end: 0.5rem;
  margin-block-end: 0.5rem;
  border: 1px solid black;

  > .MuiChip-deleteIcon {
    color: ${stihlColor.black};
  }

  ,
  > .MuiChip-label {
    padding-block-start: 0.25rem;
  }
`;

const StyledClearFiltersGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  block-size: 65px;
  margin-block-start: 1rem;
`;

type Chip = {
  id: string;
  displayValue: ReactNode;
};

type StihlFilterChipsProps = {
  chips: Chip[];
  setChips: (chips: Chip[]) => void;
  isClearAllFiltersButtonShown?: boolean;
  clearAllLabel?: ReactNode;
  error?: ReactNode;
};

const StihlFilterChips: FC<StihlFilterChipsProps> = ({
  chips,
  setChips,
  error,
  clearAllLabel,
  isClearAllFiltersButtonShown = true,
}) => {
  function handleDelete(chipId: string): void {
    const remainingChips = chips.filter((chip) => chip.id !== chipId);
    setChips(remainingChips);
  }

  function clearFilters(): void {
    setChips([]);
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ minHeight: '28px' }}>
        {error}
      </Grid>

      <Grid item xs={9} style={{ minHeight: '60px', marginTop: '1rem' }}>
        <StyledChipContainer>
          {chips.map((chip) => {
            return (
              <StyledChip
                key={chip.id}
                label={chip.displayValue}
                variant="outlined"
                onDelete={() => handleDelete(chip.id)}
              />
            );
          })}
        </StyledChipContainer>
      </Grid>
      <StyledClearFiltersGrid item xs={3}>
        {isClearAllFiltersButtonShown && chips.length > 0 && (
          <StyledClearChip
            label={clearAllLabel ?? 'Clear all'}
            icon={<StihlIconBin />}
            onClick={clearFilters}
          />
        )}
      </StyledClearFiltersGrid>
    </Grid>
  );
};

export default StihlFilterChips;
