import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import StihlSelect from '../../../../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import { stihlColor } from '../../../../../../base/stihl-material-ui/theme/stihl-style-guide';

export const EventLogFilterLabel = styled(InputLabel)`
  color: ${stihlColor.black};
`;

export const EventLogFilterSelect = styled(StihlSelect)`
  inline-size: 16rem;
`;
