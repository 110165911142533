import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconTextFlexbox from '../../../../../base/stihl-material-ui/components/stihl-icon-text-box/stihl-icon-text-flexbox';
import StihlIconPause from '../../../../../base/stihl-material-ui/icons/stihl-icon-pause';
import StihlIconPlay from '../../../../../base/stihl-material-ui/icons/stihl-icon-play';
import CardFieldContent from '../card-field-content/card-field-content';

const CenteredStihlIconPlay = styled(StihlIconPlay)`
  align-self: center;
`;

const CenteredStihlIconPause = styled(StihlIconPause)`
  align-self: center;
`;

type CardFieldAccessPinCodeProps = {
  title: string;
  value: boolean;
  icon?: ReactNode;
};

const CardFieldAccessPinCode: FC<CardFieldAccessPinCodeProps> = ({
  value,
  title,
  icon,
}) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={t(title)} icon={icon}>
      <StihlIconTextFlexbox>
        {value ? (
          <CenteredStihlIconPlay data-testid="playIcon" />
        ) : (
          <CenteredStihlIconPause color="grey" data-testid="pauseIcon" />
        )}

        <Typography
          sx={{
            marginLeft: '0.5rem',
          }}
        >
          {value
            ? t('supportFunctions.active')
            : t('supportFunctions.inactive')}
        </Typography>
      </StihlIconTextFlexbox>
    </CardFieldContent>
  );
};

export default CardFieldAccessPinCode;
