import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import type { CardFieldDateValue } from '../../../model/card-field.model';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldDateProps = {
  name: string;
  value: CardFieldDateValue;
};

const CardFieldDate: FC<CardFieldDateProps> = ({ name, value }) => {
  const { toLocaleDateString, toLocaleDateTimeString } = useDateFormatting();

  const dateString = useMemo(() => {
    const date = new Date(value.date);

    return value.isDateOnly
      ? toLocaleDateString(date)
      : toLocaleDateTimeString(date);
  }, [
    toLocaleDateString,
    toLocaleDateTimeString,
    value.date,
    value.isDateOnly,
  ]);

  return (
    <CardFieldContent name={name}>
      <Typography>{dateString}</Typography>
    </CardFieldContent>
  );
};

export default CardFieldDate;
