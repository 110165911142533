import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { CardFieldDeviceOverallStateValue } from '../../../model/card-field.model';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldStatusProps = {
  name: string;
  value: CardFieldDeviceOverallStateValue;
};

const CardFieldStatus: FC<CardFieldStatusProps> = ({ name, value }) => {
  const { t } = useTranslation();
  return (
    <CardFieldContent name={name}>
      <Typography>{t(value.state)}</Typography>
    </CardFieldContent>
  );
};

export default CardFieldStatus;
