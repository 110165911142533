import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlIconDemo from '../../../../../base/stihl-material-ui/icons/stihl-icon-demo';
import CardFieldContent from '../card-field-content/card-field-content';

const FlexGrid = styled(Grid)`
  display: flex;
`;

const CenteredStihlIconDemo = styled(StihlIconDemo)`
  align-self: center;
`;

type CardFieldDemoModeProps = {
  name: string;
};

const CardFieldDemoMode: FC<CardFieldDemoModeProps> = ({ name }) => {
  const { t } = useTranslation();

  return (
    <CardFieldContent name={name}>
      <Grid container alignItems="center" data-testid="demoMode">
        <FlexGrid item xs={1}>
          <CenteredStihlIconDemo />
        </FlexGrid>

        <Grid item xs>
          <Typography>{t('supportFunctions.active')}</Typography>
        </Grid>
      </Grid>
    </CardFieldContent>
  );
};

export default CardFieldDemoMode;
