import styled from '@emotion/styled';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary-loading';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import { DeviceModel } from '../../../model';
import type { CertificateStatusCard as CertificateStatusCardValue } from '../../../model/card.model';
import {
  useCertificateStatus,
  useRenewDeviceCertificate,
} from '../../../service/device-service/device-service';
import { CardBase } from '../generic-card/generic-card';
import {
  CommunicationCertificateStatusSection,
  DiagnosisCertificateStatusSection,
} from './certificate-status-section';

const RenewCertificateButton = styled(StihlButtonSecondaryLoading)`
  display: block;
  margin-block-start: 1rem;
`;

type CertificateStatusCardProps = {
  deviceId: string;
  deviceModel: DeviceModel;
  cardData: CertificateStatusCardValue;
};

export const CertificateStatusCard: FC<CertificateStatusCardProps> = ({
  deviceId,
  deviceModel,
  cardData,
}) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const certificateStatus = useCertificateStatus(deviceId, deviceModel);
  const renewDeviceCertificate = useRenewDeviceCertificate(
    deviceId,
    deviceModel,
  );

  useEffect(() => {
    if (certificateStatus.isError) {
      setAlert({
        isOpen: true,
        message: t('certificateStatus.alertMessage'),
        severity: 'error',
      });
    }
  }, [certificateStatus.isError, setAlert, t]);

  function onCertificateStatusButtonClick(): void {
    void certificateStatus.refetch();
  }

  function onRenewCertificateButtonClick(): void {
    renewDeviceCertificate.mutate(undefined, {
      onSuccess: () => {
        setAlert({
          isOpen: true,
          message: t('certificateStatus.renewRequestSuccessful'),
          severity: 'success',
        });
      },
      onError: () => {
        setAlert({
          isOpen: true,
          message: t('certificateStatus.renewRequestFailed'),
          severity: 'error',
        });
      },
    });
  }

  return (
    <CardBase title={cardData.title}>
      {!!certificateStatus.data && (
        <>
          <CommunicationCertificateStatusSection
            certificateStatus={certificateStatus.data}
            deviceModel={deviceModel}
          />
          {deviceModel === DeviceModel.connectedBox ? null : (
            <DiagnosisCertificateStatusSection
              certificateStatus={certificateStatus.data}
            />
          )}
        </>
      )}

      <StihlButtonSecondaryLoading
        loading={certificateStatus.isFetching}
        onClick={onCertificateStatusButtonClick}
      >
        {t('certificateStatus.button')}
      </StihlButtonSecondaryLoading>
      {deviceModel === DeviceModel.connectedBox && (
        <RenewCertificateButton
          loading={renewDeviceCertificate.isPending}
          onClick={onRenewCertificateButtonClick}
        >
          {t('certificateStatus.renewButton')}
        </RenewCertificateButton>
      )}
    </CardBase>
  );
};

export default CertificateStatusCard;
