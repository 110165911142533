import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useDateFormatting } from '../../../../../../base/date-formatting/date-formatting';
import { StihlTableRow } from '../../../../../../base/stihl-material-ui/components/stihl-table/stihl-table-row';
import type { EventLogEntry } from '../../../../model/event-log.model';

export type EventLogTableProps = {
  eventLogEntries: EventLogEntry[];
};

const EventLogTableBody = ({
  eventLogEntries,
}: EventLogTableProps): JSX.Element => {
  const { toLocaleDateTimeString } = useDateFormatting();

  return (
    <TableBody>
      {eventLogEntries.map((row) => {
        const {
          sequenceNumber,
          sequenceRowNumber,
          lastUpdatedTimestamp,
          type,
          ...entryValues
        } = row;
        return (
          <StihlTableRow
            key={`${sequenceNumber}_${sequenceRowNumber}`}
            id={`${sequenceNumber}_${sequenceRowNumber}`}
            shouldHaveCheckbox={false}
          >
            <TableCell width="20%" align="left">
              {toLocaleDateTimeString(new Date(lastUpdatedTimestamp))}
            </TableCell>
            <TableCell width="30%" align="left">
              {type}
            </TableCell>
            <TableCell width="50%" align="left">
              {Object.keys(entryValues).map((key) => {
                return (
                  <Typography key={key}>
                    {`${key}: ${entryValues[key]}`}
                  </Typography>
                );
              })}
            </TableCell>
          </StihlTableRow>
        );
      })}
    </TableBody>
  );
};

export default EventLogTableBody;
