import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonPrimaryLoading from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-primary-loading';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import Pagination from '../../../../app-shell/ui/pagination/pagination';
import type { EventLogCard as EventLogCardValue } from '../../../model/card.model';
import type { DeviceModel } from '../../../model/device.model';
import type { EventLogEntry } from '../../../model/event-log.model';
import { useEventLog } from '../../../service/device-service/device-service';
import CardField from '../../card-field/card-field';
import { CardBase, ContentWrapper } from '../generic-card/generic-card';
import EventLogFilter from './filter/event-log-filter';
import EventLogTableBody from './table/event-log-table-body';
import { EventLogTableHeader } from './table/event-log-table-header';

export type EventLogCardProps = {
  deviceId: string | undefined;
  deviceModel: DeviceModel | undefined;
  cardData: EventLogCardValue;
};

const EventLogCard: FC<EventLogCardProps> = ({
  deviceId,
  deviceModel,
  cardData,
}) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();

  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);
  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  const [hourTimeSpan, setHourTimeSpan] = useState<number | undefined>();
  const [eventTypes, setEventTypes] = useState<string[]>([]);

  const eventLogData = useEventLog(deviceId ?? '', deviceModel ?? '');

  const filteredEventLogData: EventLogEntry[] = useMemo(() => {
    let filteringDate: Date | undefined;

    if (hourTimeSpan) {
      filteringDate = new Date();
      filteringDate.setHours(filteringDate.getHours() - hourTimeSpan);
    }

    return (
      eventLogData.data?.eventLog.filter((entry) => {
        if (
          filteringDate &&
          new Date(entry.lastUpdatedTimestamp) < filteringDate
        )
          return false;

        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (eventTypes.length > 0 && !eventTypes.includes(entry.type))
          return false;

        return true;
      }) ?? []
    );
  }, [eventLogData.data?.eventLog, hourTimeSpan, eventTypes]);

  const eventTypesAvailable = useMemo(
    () => [...new Set(eventLogData.data?.eventLog.map((entry) => entry.type))],
    [eventLogData.data?.eventLog],
  );

  useEffect(() => {
    if (eventLogData.isError) {
      setAlert({
        isOpen: true,
        severity: 'error',
        message: t('eventLog.getEventLogDataError'),
      });
    }
  }, [eventLogData.isError, setAlert, t]);

  const fetchEventLog = (): void => {
    void eventLogData.refetch();
  };

  const eventLogLength = eventLogData.data?.eventLog.length;
  return (
    <>
      <CardBase title={cardData.title} data-testid="eventLogCard">
        <CardContent sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
          {cardData.cardFields.map((data) => (
            <ContentWrapper key={data.title}>
              <CardField value={data} />
            </ContentWrapper>
          ))}
        </CardContent>
        {!eventLogLength && (
          <StihlButtonPrimaryLoading
            data-testid="getEventLogButton"
            onClick={fetchEventLog}
            loading={eventLogData.isFetching}
          >
            {t('eventLog.retrieveData')}
          </StihlButtonPrimaryLoading>
        )}
        {eventLogLength === 0 && (
          <Typography sx={{ marginTop: '1rem' }}>
            {t('eventLog.noEntries')}
          </Typography>
        )}
        {!!eventLogLength && eventLogLength > 0 && (
          <>
            <EventLogFilter
              hourTimeSpan={hourTimeSpan}
              setHourTimeSpan={setHourTimeSpan}
              eventTypesAvailable={eventTypesAvailable}
              eventTypes={eventTypes}
              setEventTypes={setEventTypes}
            />

            <TableContainer>
              <Table data-testid="eventLogTable">
                <EventLogTableHeader rowCount={filteredEventLogData.length} />
                <EventLogTableBody
                  eventLogEntries={[...filteredEventLogData].slice(
                    firstToDisplay,
                    lastToDisplay,
                  )}
                />
              </Table>
            </TableContainer>
          </>
        )}
      </CardBase>
      {!!eventLogLength && eventLogLength > 0 && (
        <Pagination
          numberOfResults={filteredEventLogData.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('eventLog.entries')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      )}
    </>
  );
};

export default EventLogCard;
