import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFormatting } from '../../../../../base/date-formatting/date-formatting';
import type { CardFieldWarrantyValue } from '../../../model/card-field.model';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldWarrantyDataProps = {
  name: string;
  value: CardFieldWarrantyValue;
};

const CardFieldWarrantyData: FC<CardFieldWarrantyDataProps> = ({
  name,
  value,
}) => {
  const { t } = useTranslation();
  const { toLocaleDateString } = useDateFormatting();

  return (
    <CardFieldContent name={name}>
      <Typography>
        {toLocaleDateString(new Date(value.warrantyStart))} -{' '}
        {toLocaleDateString(new Date(value.warrantyEnd))} (
        {t('productionAndIdentificationData.extension', {
          count: value.warrantyExtensionNumber,
        })}
        )
      </Typography>
    </CardFieldContent>
  );
};

export default CardFieldWarrantyData;
